import api from '../axios'

//근무요청 목록 조회 (구인회원)
export const getMatchPeopleList = async ({
  employerId,
  documentNo,
  pushStatus,
  schKeyword,
  pageNumber,
}) => {
  const response = await api('GET_MATCH_PEOPLE_LIST', {
    employerId,
    documentNo,
    pushStatus,
    schKeyword,
    pageNumber,
  })
  return response
}

//근무요청 목록 조회 (구인회원)
export const getRequestListForEmployer = async ({
  employerId,
  reqStartDt,
  workStartDt,
  workEndDt,
  reqEndDt,
  pushStatus,
  schKeyword,
  pageNumber,
}) => {
  const response = await api('GET_REQUEST_LIST_FOR_EMPLOYER', {
    employerId,
    reqStartDt,
    workStartDt,
    workEndDt,
    reqEndDt,
    pushStatus,
    schKeyword,
    pageNumber,
  })
  return response
}

//근무요청 목록 조회 (구직회원)
export const getRequestListForEmployee = async ({
  seekerId,
  reqStartDt,
  workStartDt,
  workEndDt,
  reqEndDt,
  pushStatus,
  schKeyword,
  pageNumber,
}) => {
  const response = await api('GET_REQUEST_LIST_FOR_EMPLOYEE', {
    seekerId,
    reqStartDt,
    workStartDt,
    workEndDt,
    reqEndDt,
    pushStatus,
    schKeyword,
    pageNumber,
  })
  return response
}

//근무요청 현황 조회
export const getRequestStatus = async ({ pushId }) => {
  const response = await api('GET_REQUEST_STATUS', {
    pushId,
  })
  return response
}

//근무요청 상태 변경
export const putRequestStatus = async ({
  pushId,
  userId,
  senderId,
  pushStatus,
  cancelReason,
}) => {
  const response = await api('PUT_REQUEST_STATUS', {
    pushId,
    userId,
    senderId,
    pushStatus,
    cancelReason,
  })
  return response
}

//근태목록 조회 (구인회원)
export const getAttendanceListForEmployer = async ({
  employerId,
  workingStartDt,
  workingEndDt,
  workingStatus,
  schKeyword,
  pageNumber,
}) => {
  const response = await api('GET_ATTENDANCE_LIST_FOR_EMPLOYER', {
    employerId,
    workingStartDt,
    workingEndDt,
    workingStatus,
    schKeyword,
    pageNumber,
  })
  return response
}

//근태목록 조회 (구직회원)
export const getAttendanceListForEmployee = async ({
  employeeId,
  workingStartDt,
  workingEndDt,
  workingStatus,
  schKeyword,
  pageNumber,
}) => {
  const response = await api('GET_ATTENDANCE_LIST_FOR_EMPLOYEE', {
    employeeId,
    workingStartDt,
    workingEndDt,
    workingStatus,
    schKeyword,
    pageNumber,
  })
  return response
}

//근태 상세 조회 (구인회원)
export const getAttendanceDetailForEmployer = async ({
  employerId,
  workingId,
}) => {
  const response = await api('GET_ATTENDANCE_DETAIL_FOR_EMPLOYER', {
    employerId,
    workingId,
  })
  return response
}

//근태 상세 조회 (구직회원)
export const getAttendanceDetailForEmployee = async ({
  employeeId,
  workingId,
}) => {
  const response = await api('GET_ATTENDANCE_DETAIL_FOR_EMPLOYEE', {
    employeeId,
    workingId,
  })
  return response
}

//근태 상태 변경
export const putAttendanceStatus = async ({
  workingId,
  userId,
  userType,
  workingStatus,
}) => {
  const response = await api('PUT_ATTENDANCE_STATUS', {
    workingId,
    userId,
    userType,
    workingStatus,
  })
  return response
}

//구직회원의 근태 평가
export const putEvalAttendanceToEmployer = async ({
  workingId,
  userId,
  userType,
  workingStatus,
  evalMessage,
  evalGrade,
}) => {
  const response = await api('PUT_EVAL_ATTENDANCE_TO_EMPLOYER', {
    workingId,
    userId,
    userType,
    workingStatus,
    evalMessage,
    evalGrade,
  })
  return response
}

export const putEvalAttendanceToEmployee = async ({
  workingId,
  userId,
  userType,
  workingStatus,
  evalMessage,
  evalGrade,
}) => {
  const response = await api('PUT_EVAL_ATTENDANCE_TO_EMPLOYEE', {
    workingId,
    userId,
    userType,
    workingStatus,
    evalMessage,
    evalGrade,
  })
  return response
}
