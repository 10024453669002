import api from '../axios'

//개인회원 로그인
export const postLoginPersonal = async ({
  userId,
  userPw,
  loginType,
  fcmToken,
}) => {
  const response = await api('POST_LOGIN_PERSONAL', {
    userId,
    userPw,
    loginType,
    fcmToken,
  })
  return response
}

//기업회원 로그인
export const postLoginCompany = async ({
  userId,
  userPw,
  loginType,
  fcmToken,
}) => {
  const response = await api('POST_LOGIN_COMPANY', {
    userId,
    userPw,
    loginType,
    fcmToken,
  })
  return response
}

//휴대폰으로 인증번호 발송
export const postSendSmsAuthNum = async ({ userNm, phone }) => {
  const response = await api('HOGOO_POST_SEND_SMS_AUTH_NUM', { userNm, phone })
  return response
}

//이메일로 인증번호 발송
export const postSendEmailAuthNum = async ({ userNm, email }) => {
  const response = await api('HOGOO_POST_SEND_EMAIL_AUTH_NUM', {
    userNm,
    email,
  })
  return response
}

//휴대폰으로 아이디 찾기
export const getFindIdWithSms = async ({ userNm, phone, certNo }) => {
  const response = await api('HOGOO_GET_FIND_ID_WITH_SMS', {
    userNm,
    phone,
    certNo,
  })
  return response
}

//이메일로 아이디 찾기
export const getFindIdWithEmail = async ({ userNm, email, certNo }) => {
  const response = await api('HOGOO_GET_FIND_ID_WITH_EMAIL', {
    userNm,
    email,
    certNo,
  })
  return response
}

//휴대폰으로 비밀번호 찾기
export const getFindPwWithSms = async ({ userId, userNm, phone, certNo }) => {
  const response = await api('HOGOO_GET_FIND_PW_WITH_SMS', {
    userId,
    userNm,
    phone,
    certNo,
  })
  return response
}

//이메일로 비밀번호 찾기
export const getFindPwWithEmail = async ({ userId, userNm, email, certNo }) => {
  const response = await api('HOGOO_GET_FIND_PW_WITH_EMAIL', {
    userId,
    userNm,
    email,
    certNo,
  })
  return response
}

//개인 회원가입
export const postJoinPersonal = async ({
  userId,
  userNm,
  userPw,
  address,
  addressDetail,
  region1DepthName,
  region2DepthName,
  region3DepthName,
  customerType,
  recommenderPhone,
  checkEmail,
  checkSms,
  checkOpen,
  checkUserInfo,
  check3Rd,
  joinType,
  joinPath,
  retentionPeriod,
  email,
  phone,
  affiliatedCompany,
  gender,
  birthday,
  nation,
  fcmToken,
}) => {
  const response = await api('POST_JOIN_PERSONAL', {
    userId,
    userNm,
    userPw,
    address,
    addressDetail,
    region1DepthName,
    region2DepthName,
    region3DepthName,
    customerType,
    recommenderPhone,
    checkEmail,
    checkSms,
    checkOpen,
    checkUserInfo,
    check3Rd,
    joinType,
    joinPath,
    retentionPeriod,
    email,
    phone,
    affiliatedCompany,
    gender,
    birthday,
    nation,
    fcmToken,
  })
  console.log('JS', address)
  console.log('JS', addressDetail)
  return response
}
//기업 회원가입
export const postJoinCompany = async ({
  userId,
  userNm,
  userPw,
  address,
  addressDetail,
  region1DepthName,
  region2DepthName,
  region3DepthName,
  customerType,
  recommenderId,
  checkEmail,
  checkSms,
  checkOpen,
  checkUserInfo,
  check3Rd,
  joinType,
  joinPath,
  retentionPeriod,
  president,
  bizNo,
  email,
  phone,
  companyNm,
  gender,
  birthday,
  nation,
  fcmToken,
}) => {
  const response = await api('POST_JOIN_COMPANY', {
    userId,
    userNm,
    userPw,
    address,
    addressDetail,
    region1DepthName,
    region2DepthName,
    region3DepthName,
    customerType,
    recommenderId,
    checkEmail,
    checkSms,
    checkOpen,
    checkUserInfo,
    check3Rd,
    joinType,
    joinPath,
    retentionPeriod,
    president,
    bizNo,
    email,
    phone,
    companyNm,
    gender,
    birthday,
    nation,
    fcmToken,
  })
  console.log('JS', address)
  return response
}

//로그아웃
export const postLogout = async ({ fcmToken }) => {
  const response = await api('POST_LOGOUT', { fcmToken })
  return response
}

// 본인인증 암호화된 키 서버 요청
export const postEncKey = async () => {
  const response = await api('HOGOO_ENC_KEY', {})
  return response
}

// 본인인증 결과값 검증 요청
export const postDecKey = async ({ certNum, rec_cert }) => {
  const response = await api('HOGOO_DEC_KEY', { certNum, rec_cert })
  return response
}

//앱 사용자 sns로그인 한 경우 fcmtoken 등록 api
export const postFcmToken = async ({ fcmToken }) => {
  const response = await api('POST_FCMTOKEN', { fcmToken })
  return response
}
