<template>
  <ul class="mobileTopMenu" v-if="isLogin">
    <li
      :class="{ on: this.NAV_ADDCLASS === 'registJob' }"
      class="mobileTopSideMenu"
      v-if="isEmployer || !isEmployee"
    >
      <router-link to="/regist-job">일자리 등록</router-link>
    </li>
    <li
      :class="{ on: this.NAV_ADDCLASS === 'findJob' }"
      class="mobileTopSideMenu"
    >
      <router-link to="/find-job">일자리 찾기</router-link>
    </li>
    <li
      :class="{ on: this.NAV_ADDCLASS === 'requestList' }"
      class="mobileTopSideMenu"
    >
      <router-link to="/request">근무 관리</router-link>
    </li>
    <li
      :class="{ on: this.NAV_ADDCLASS === 'eduApply' }"
      class="mobileTopSideMenu"
    >
      <router-link to="/eduApply">교육 신청</router-link>
    </li>
    <li
      :class="{ on: this.NAV_ADDCLASS === 'requirement' }"
      class="mobileTopSideMenu"
    >
      <router-link to="/community">커뮤니티</router-link>
    </li>
  </ul>
  <ul class="mobileTopMenu" v-else>
    <li
      :class="{ on: this.NAV_ADDCLASS === 'login' }"
      class="mobileTopSideMenu"
    >
      <router-link to="/login">로그인</router-link>
    </li>
    <li :class="{ on: this.NAV_ADDCLASS === 'join' }" class="mobileTopSideMenu">
      <router-link to="/join">회원가입</router-link>
    </li>
    <li
      :class="{ on: this.NAV_ADDCLASS === 'findJob' }"
      class="mobileTopSideMenu"
    >
      <router-link to="/find-job">일자리 찾기</router-link>
    </li>
    <li
      :class="{ on: this.NAV_ADDCLASS === 'eduApply' }"
      class="mobileTopSideMenu"
    >
      <router-link to="/eduApply">교육신청</router-link>
    </li>
    <li
      :class="{ on: this.NAV_ADDCLASS === 'requirement' }"
      class="mobileTopSideMenu"
    >
      <router-link to="/community">커뮤니티</router-link>
    </li>
  </ul>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['isLogin']),
  },
  created() {},
  updated() {},
}
</script>
