<template>
  <div
    id="all-menu"
    class="all-menu-wrap"
    :class="{ on, ing }"
    @click="onClickMenu"
    ref="allMenu"
  >
    <div class="cont">
      <!--로그인 전-->
      <div v-if="!isLogin" class="lnb-login-before">
        <div class="lnb-mob-header">
          <div class="mlogo">
            <img style="height: 29.5px" src="~assets/image/etc/etc-logo.png" />
          </div>
          <button type="button" @click="close">
            <i class="el-icon-close"></i>
          </button>
        </div>
        <ul>
          <li @click="close"><router-link to="/login">로그인</router-link></li>
          <li @click="close"><router-link to="/join">회원가입</router-link></li>
        </ul>
        <!-- <div class="before-btn-wrap">
          <button type="button" @click="moveToPage('/regist-job', close)">
            일자리 등록
          </button>
        </div> -->
      </div>

      <!--로그인 후-->
      <mobile-user-info v-else @close="close"></mobile-user-info>

      <div class="lnb-scrolls">
        <ul class="lnb-ico-menu">
          <li v-if="isEmployer || !isEmployee" @click="close">
            <router-link to="/regist-job">
              <div class="txt">일자리 등록</div>
            </router-link>
          </li>
          <li @click="close">
            <router-link to="/find-job">
              <div class="txt">일자리 찾기</div>
            </router-link>
          </li>
          <li v-if="isLogin" @click="toggleMenu('REQUEST')">
            <a
              href="javascript:void(0)"
              class="more-type"
              :class="{ on: requestMenu }"
            >
              <div class="txt">근무 관리</div>
              <div class="ico">
                <img src="~assets/image/ico/ico-lnb-arr.svg" />
              </div>
            </a>
            <ul>
              <li @click="close">
                <router-link to="/request"> 근무 요청 </router-link>
              </li>
              <li @click="close">
                <router-link to="/attendance"> 출 &#183; 퇴근 </router-link>
              </li>
              <li @click="close">
                <router-link to="/mypage/calc-history"> 정산 </router-link>
              </li>
            </ul>
          </li>
          <li @click="toggleMenu('EDUAPPLY')">
            <a
              href="javascript:void(0)"
              :class="{ on: eduApply }"
              class="more-type"
            >
              <div class="txt">교육 신청</div>
              <div class="ico">
                <img src="~assets/image/ico/ico-lnb-arr.svg" />
              </div>
            </a>
            <ul>
              <li @click="close">
                <router-link to="/eduApply">교육 신청</router-link>
              </li>
              <li @click="close">
                <!--router-link to="/account">계정 설정</!--router-link-->
                <router-link to="/video-watch">교육 동영상</router-link>
              </li>
              <li @click="close">
                <!--router-link to="/account">계정 설정</!--router-link-->
                <router-link to="/resume/edu">교육 이수 내역</router-link>
              </li>
            </ul>
          </li>
          <li @click="toggleMenu('NOTICE')">
            <a
              href="javascript:void(0)"
              :class="{ on: noticeMenu }"
              class="more-type"
            >
              <div class="txt">커뮤니티</div>
              <div class="ico">
                <img src="~assets/image/ico/ico-lnb-arr.svg" />
              </div>
            </a>
            <ul>
              <li @click="close">
                <router-link to="/community">자주 묻는 질문</router-link>
              </li>
              <li @click="close">
                <!--router-link to="/account">계정 설정</!--router-link-->
                <router-link to="/Notice">공지사항</router-link>
              </li>
              <li @click="close">
                <router-link to="/EventNnews">이벤트 &#38; 뉴스</router-link>
              </li>
              <li @click="close">
                <router-link to="/Affiliates">제휴사 현황</router-link>
              </li>
            </ul>
          </li>
          <li v-if="isLogin" @click="toggleMenu('MYPAGE')">
            <a
              href="javascript:void(0)"
              :class="{ on: myPageMenu }"
              class="more-type"
            >
              <div class="txt">마이페이지</div>
              <div class="ico">
                <img src="~assets/image/ico/ico-lnb-arr.svg" />
              </div>
            </a>
            <ul>
              <li v-if="isEmployee" @click="close">
                <router-link to="/resume/career">이력서 관리</router-link>
              </li>
              <li @click="close">
                <!--router-link to="/account">계정 설정</!--router-link-->
                <router-link to="/account">기본정보 수정</router-link>
              </li>
              <li v-if="isEmployee" @click="close">
                <router-link to="/mypage/smart-match">스마트매칭</router-link>
              </li>
              <!-- <li v-if="isEmployer" @click="close">
                <router-link to="/mypage/purchase-history"
                  >구매 내역</router-link
                >
              </li> -->
              <!--li @click="close">
                <router-link to="/mypage/work-history">근무 내역</router-link>
              </li-->
              <li @click="close">
                <router-link to="/mypage/calc-history">정산 내역</router-link>
              </li>
              <li @click="close">
                <router-link to="/mypage/exchange-request"
                  >환전 요청</router-link
                >
              </li>
              <li @click="close">
                <router-link to="/mypage/point-history">
                  포인트 내역
                </router-link>
              </li>
              <li @click="close">
                <router-link to="/mypage/mileage-history">
                  마일리지 내역
                </router-link>
              </li>
            </ul>
          </li>
          <li @click="toggleMenu('HOGOO')">
            <a
              href="javascript:void(0)"
              class="more-type"
              :class="{ on: hogooMenu }"
            >
              <div class="txt">호구153</div>
              <div class="ico">
                <img src="~assets/image/ico/ico-lnb-arr.svg" />
              </div>
            </a>
            <ul>
              <li @click="close">
                <router-link to="/compIntroduc">회사 소개</router-link>
              </li>
              <li @click="close">
                <router-link to="/introduce">호구153 소개</router-link>
              </li>
              <li @click="close">
                <router-link to="/terms/personal">
                  개인회원 이용약관
                </router-link>
              </li>
              <li @click="close">
                <router-link to="/terms/business">
                  기업회원 이용약관
                </router-link>
              </li>
              <li @click="close">
                <router-link to="/privacy">개인정보 처리방침</router-link>
              </li>
              <li @click="close">
                <router-link to="/email-collect"
                  >이메일무단수집거부
                </router-link>
              </li>
              <li @click="close">
                <router-link to="/app-Guide">앱 사용 설명서</router-link>
              </li>
            </ul>
          </li>
          <li @click="close" v-if="account.levelNm === '관리자'">
            <router-link to="/shoppingMall">
              <div class="txt">복지몰</div>
            </router-link>
          </li>
          <li @click="close">
            <router-link to="/Sharing">
              <div class="txt">호구153 추천하기</div>
            </router-link>
          </li>
        </ul>
      </div>

      <div class="lnb-ft-wrap">
        <ul>
          <!--li><router-link to="/customer">고객센터</router-link></!--li-->
          <li @click="close">
            <router-link to="/community">커뮤니티</router-link>
          </li>
          <!--li><router-link to="/contact">Contact Us</router-link></!--li-->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import popupMixin from 'Mixins/popup'
import { mapGetters } from 'vuex'
import MobileUserInfo from './MobileUserInfo'

const OPEN_MENU_NAME = {
  REQUEST: 'REQUEST',
  NOTICE: 'NOTICE',
  MYPAGE: 'MYPAGE',
  HOGOO: 'HOGOO',
  EDUAPPLY: 'EDUAPPLY',
}

export default {
  components: {
    MobileUserInfo,
  },
  mixins: [popupMixin],
  data() {
    return {
      requestMenu: false,
      myPageMenu: false,
      hogooMenu: false,
      noticeMenu: false,
      eduApply: false,
    }
  },
  computed: {
    ...mapGetters(['isLogin']),
  },
  methods: {
    toggleMenu(name) {
      switch (name) {
        case OPEN_MENU_NAME.REQUEST:
          this.requestMenu = !this.requestMenu
          break
        case OPEN_MENU_NAME.MYPAGE:
          this.myPageMenu = !this.myPageMenu
          break
        case OPEN_MENU_NAME.EDUAPPLY:
          this.eduApply = !this.eduApply
          break
        case OPEN_MENU_NAME.NOTICE:
          this.noticeMenu = !this.noticeMenu
          break
        case OPEN_MENU_NAME.HOGOO:
          this.hogooMenu = !this.hogooMenu
          break
      }
    },
    onClickMenu(event) {
      if (event.target === this.$refs['allMenu']) this.close()
    },
  },
}
</script>
