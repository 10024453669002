import Axios from 'axios'
import Cookies from 'js-cookie'
import URL, { BASE_URL } from './api'
import ERROR_CODE from 'Configs/error'
import Vue from 'vue'
import { clearCookie } from 'Utils/auth'
import { setAccessTokenToCookie } from 'Utils/auth'
import { getLoginInfo, sendMessageToApp } from 'Utils/message'

//axios instance
const axios = Axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
})

//global response handling
axios.interceptors.response.use(response => {
  return response
})

const api = async (constant, params) => {
  //회원가입 URL 예외처리
  if (
    constant.indexOf('JOIN') > -1 ||
    constant.indexOf('HOGOO') > -1 ||
    constant.indexOf('FAQ') > -1 ||
    constant.indexOf('BOARD') > -1 ||
    constant === 'GET_BANNER_LIST' ||
    constant === 'GET_SPECIAL_LIST' ||
    constant === 'GET_URGENT_LIST' ||
    constant === 'GET_GENERAL_LIST' ||
    constant === 'GET_UPDATE_READ_CNT' ||
    constant === 'POST_APPLY_REQUEST' ||
    constant === 'PUT_ONE_ON_ONE_INFO' ||
    constant === 'PUT_ONE_ON_ONE_ANSWER'
  )
    axios.defaults.baseURL = BASE_URL.replace('/v1', '')
  //로그인 URL 예외처리
  else if (constant.indexOf('POST_LOGIN') > -1)
    axios.defaults.baseURL = BASE_URL.replace('/api/v1', '')
  else axios.defaults.baseURL = BASE_URL

  constant = constant.toUpperCase()

  //get access token from cookies
  const accessToken = Cookies.get('accessToken')
  if (accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
  }
  axios.defaults.headers.common['Accept'] = `*/*`

  const method = URL[constant][0].toLowerCase()
  let url = URL[constant][1]
  const custom = URL[constant][2] || null
  let parameter = params

  // URI 전환
  url = url.replace(/{(\w+)}/g, (match, $1) => {
    const alt = params[$1]
    delete params[$1]
    delete parameter[$1]
    return alt
  })

  //custom
  if (custom && 'form' === custom.type) {
    axios.defaults.headers['Content-Type'] = 'multipart/form-data'

    //Extract params
    let paramsOption = parameter
    parameter = new FormData()
    for (let param in paramsOption) {
      if (Array.isArray(paramsOption[param])) {
        paramsOption[param].forEach(item => {
          parameter.append(param, item)
        })
      } else parameter.append(param, paramsOption[param])
    }
  } else if (custom && 'x-www-form' === custom.type) {
    axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'

    //Extract params
    let paramsOption = parameter
    parameter = new URLSearchParams()
    for (let param in paramsOption) {
      parameter.append(param, paramsOption[param])
    }
  } else {
    axios.defaults.headers['Content-Type'] = 'application/json'
  }

  const request = {
    method,
    url,
  }

  if (method === 'get') {
    request['params'] = parameter
  } else {
    request['data'] = parameter
  }
  //console.log(parameter)
  const response = await axios(request)
  const { data } = response
  //console.log(response)
  //sendMessageToApp('LOG', { request })

  //로그인 리디렉트
  if (data.status === ERROR_CODE.ACCESS_DENIED) {
    console.error('not allowed')
    sendMessageToApp('LOG', { message: 'not allowed' })
    Vue.$store.commit('SET_IS_LOGIN', false)
    Vue.$store.commit('CLEAR_ACCOUNT_INFO')
    clearCookie()

    //앱 : @TODO 페이지에 머무는 상태에서 토큰이 만료된 경우 API에서 로그인 만료가 발생될 수 있다.
    //이 경우 사용자가 하려던 행동이 실패하고 다시 시도해야할 것이다.
    if (Vue.$store.getters.isApp) {
      if (request.url !== '/user-info/id') {
        const res = await getLoginInfo()
        //sendMessageToApp('LOG', { 'res-axios': res })

        if (!res) {
          location.href = '/login'
          sendMessageToApp('LOG', { message: 'redirect to login' })
          throw data.error.message
        }

        const { userId, accessToken } = res
        setAccessTokenToCookie(accessToken)

        Vue.$store.commit('SET_ACCOUNT_USER_ID', userId)
        Vue.$store.commit('SET_IS_LOGIN', true)

        Vue.$toasted.show('로그인 정보가 갱신 되었습니다. 다시 시도해주세요.')
        // sendMessageToApp('LOG', {
        //   message: 'reload',
        //   cookie: Cookies.get('accessToken'),
        // })
        return
      } else location.href = Vue.$route.path
    } else {
      location.href = `/login`
      throw data.error.message
    }
  } else if (data.status === 0 || !data.error) {
    return response.data
  } else {
    let message = ''
    //throw error
    if (data.error.message.indexOf('\n\n') > -1)
      message = data.error.message.split('\n\n')[0]
    else message = data.error.message

    Vue.$toasted.error(message)
    throw message
  }
}

export default api
