import api from '../axios'

//일자리 목록 조회
export const getRecruitList = async ({
  schKeyword,
  schRegion,
  schWorkPeriod,
  schBusiness,
  schPosition,
  schPaymentMin,
  schPaymentMax,
  schCompanyId,
  distance,
}) => {
  const response = await api('GET_RECRUIT_LIST', {
    schKeyword,
    schRegion,
    schWorkPeriod,
    schBusiness,
    schPosition,
    schPaymentMin,
    schPaymentMax,
    schCompanyId,
    distance,
  })
  return response
}

//배너 목록 조회
export const getBannerList = async ({
  schKeyword,
  schRegion,
  schWorkPeriod,
  schBusiness,
  schPosition,
  schPaymentMin,
  schPaymentMax,
  userId,
  schCompanyId,
  schPushStatus,
  schLat,
  schLon,
  pageNumber,
  pageSize,
  distance,
}) => {
  const response = await api('GET_BANNER_LIST', {
    schKeyword,
    schRegion,
    schWorkPeriod,
    schBusiness,
    schPosition,
    schPaymentMin,
    schPaymentMax,
    userId,
    schCompanyId,
    schPushStatus,
    schLat,
    schLon,
    pageNumber,
    pageSize,
    distance,
  })
  return response
}

//스페셜 목록 조회
export const getSpecialList = async ({
  schKeyword,
  schRegion,
  schWorkPeriod,
  schBusiness,
  schPosition,
  schPaymentMin,
  schPaymentMax,
  userId,
  schCompanyId,
  schPushStatus,
  schLat,
  schLon,
  pageNumber,
  pageSize,
  distance,
}) => {
  const response = await api('GET_SPECIAL_LIST', {
    schKeyword,
    schRegion,
    schWorkPeriod,
    schBusiness,
    schPosition,
    schPaymentMin,
    schPaymentMax,
    userId,
    schCompanyId,
    schPushStatus,
    schLat,
    schLon,
    pageNumber,
    pageSize,
    distance,
  })
  return response
}

//긴급 목록 조회
export const getUrgentList = async ({
  schKeyword,
  schRegion,
  schWorkPeriod,
  schBusiness,
  schPosition,
  schPaymentMin,
  schPaymentMax,
  userId,
  schCompanyId,
  schPushStatus,
  schLat,
  schLon,
  pageNumber,
  pageSize,
  distance,
}) => {
  const response = await api('GET_URGENT_LIST', {
    schKeyword,
    schRegion,
    schWorkPeriod,
    schBusiness,
    schPosition,
    schPaymentMin,
    schPaymentMax,
    userId,
    schCompanyId,
    schPushStatus,
    schLat,
    schLon,
    pageNumber,
    pageSize,
    distance,
  })
  return response
}

//일반 목록 조회
export const getGeneralList = async ({
  schKeyword,
  schRegion,
  schWorkPeriod,
  schBusiness,
  schPosition,
  schPaymentMin,
  schPaymentMax,
  userId,
  schCompanyId,
  schPushStatus,
  schLat,
  schLon,
  pageNumber,
  pageSize,
  distance,
}) => {
  const response = await api('GET_GENERAL_LIST', {
    schKeyword,
    schRegion,
    schWorkPeriod,
    schBusiness,
    schPosition,
    schPaymentMin,
    schPaymentMax,
    userId,
    schCompanyId,
    schPushStatus,
    schLat,
    schLon,
    pageNumber,
    pageSize,
    distance,
  })
  return response
}

//일자리 상세 조회
export const getRecruitDetail = async ({ documentNo }) => {
  const response = await api('GET_RECRUIT_DETAIL', { documentNo })
  return response
}

//지원하기
export const postApplyJob = async ({ jobRequestId, senderId, pushStatus }) => {
  const response = await api('POST_APPLY_REQUEST', {
    jobRequestId,
    senderId,
    pushStatus,
  })
  return response
}
