export const TERM_LIST = [
  { name: '단기', on: false, value: 'SHORT' },
  { name: '장기', on: false, value: 'LONG' },
  { name: '테마', on: false, value: 'THEME' },
]

export const TERM_LIST_TWO = [
  { name: '전체', on: false, value: 'FULL' },
  { name: '단기', on: false, value: 'SHORT' },
  { name: '장기', on: false, value: 'LONG' },
  { name: '테마', on: false, value: 'THEME' },
]

export const LOCAL_LIST = [
  { name: '전체', on: false, value: '' },
  { name: '서울', on: false, value: '' },
  { name: '경기', on: false, value: '' },
  { name: '인천', on: false, value: '' },
  { name: '강원', on: false, value: '' },
  { name: '대전', on: false, value: '' },
  { name: '충남', on: false, value: '' },
  { name: '충북', on: false, value: '' },
  { name: '부산', on: false, value: '' },
  { name: '울산', on: false, value: '' },
  { name: '경남', on: false, value: '' },
  { name: '경북', on: false, value: '' },
  { name: '대구', on: false, value: '' },
  { name: '광주', on: false, value: '' },
  { name: '전남', on: false, value: '' },
  { name: '전북', on: false, value: '' },
  { name: '세종', on: false, value: '' },
  { name: '제주', on: false, value: '' },
]

export const LOCAL_LIST_TWO = [
  { name: '서울', on: false, value: '' },
  { name: '경기', on: false, value: '' },
  { name: '인천', on: false, value: '' },
  { name: '강원', on: false, value: '' },
  { name: '대전', on: false, value: '' },
  { name: '충남', on: false, value: '' },
  { name: '충북', on: false, value: '' },
  { name: '부산', on: false, value: '' },
  { name: '울산', on: false, value: '' },
  { name: '경남', on: false, value: '' },
  { name: '경북', on: false, value: '' },
  { name: '대구', on: false, value: '' },
  { name: '광주', on: false, value: '' },
  { name: '전남', on: false, value: '' },
  { name: '전북', on: false, value: '' },
  { name: '세종', on: false, value: '' },
  { name: '제주', on: false, value: '' },
]

export const CATE_LIST = [
  { name: '비즈니스 호텔', on: false, value: 'BUSINESS' },
  { name: '5성급 호텔', on: false, value: 'FH' },
  { name: '4성급 호텔', on: false, value: 'FOH' },
  { name: '3성급 호텔', on: false, value: 'TH' },
  { name: '2성급 호텔', on: false, value: 'TWH' },
  { name: '1성급 호텔', on: false, value: 'OH' },
]

export const JOB_TYPE_LIST = [
  { name: '객실관리', on: false, value: '1' },
  { name: '후론트', on: false, value: '3' },
  { name: '식음(F&B)', on: false, value: '5' },
  { name: '주방 KITCHEN', on: false, value: '12' },
  { name: '시설 보안', on: false, value: '13' },
]

export const JOB_TYPE_LIST_TWO = [
  { name: '전체', on: false, value: '99' },
  { name: '객실관리', on: false, value: '1' },
  { name: '프론트', on: false, value: '3' },
  { name: '식음', on: false, value: '5' },
  { name: '주방', on: false, value: '12' },
  { name: '시설보안', on: false, value: '13' },
]
