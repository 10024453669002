/*
    API URL

    API 목록
    1.일자리 등록 : REGIST_JOB
    2.일자리 찾기 : FIND_JOB
    3.근무요청 : REQUEST_WORK
    4.로그인/회원가입 : USER
    5.마이페이지 : MYINFO
    6.HOGOO153 : HOOGOO
    7.이용방법 : INSTRUCTION
*/
export const URL = process.env.VUE_APP_API_URL
export const BASE_URL = `${URL}/api/v1`
const GET = 'GET'
const POST = 'POST'
const PUT = 'PUT'
const DELETE = 'DELETE'
const FORM = { type: 'form' }
const X_FORM = { type: 'x-www-form' }

export default {
  //코드목록 조회
  GET_CODE_LIST: [GET, '/codes'], // 공통 코드 목록 조회

  //코드목록 조회
  GET_CODE_DETAIL_LIST: [GET, '/codes/detail'], // 공통 코드 목록 조회

  //일자리 등록
  //이전공고
  GET_PREVIOUS_RECRUITS_LIST: [GET, '/recruits/last'], //이전 공고 목록 조회
  GET_ADVERTI_SEMENT_LIST: [GET, '/recruits/advertisement'], //공고 별 목록 조회
  EDIT_PREVIOUS_RECRUIT: [GET, '/recruits/current'], //이전 공고 수정 확인
  COPY_PREVIOUS_RECRUIT: [POST, '/recruits/replica'], //이전 공고 복사 요청
  DELETE_PREVIOUS_RECRUIT: [DELETE, '/recruits/remove'], //이전 공고 복사 요청

  //근무지등록
  GET_EXIST_ADDRESS: [GET, '/user-info/address'], //기존 주소 조회
  //GET_BUSINESS_TYPE_LIST: [GET, '/codes'], //숙박유형 목록 조회
  SAVE_JOB_LOCATION: [POST, '/job-posts'], //근무지 저장
  GET_EXIST_JOB_LOCATION: [GET, '/job-posts/address'], //근무지 저장 조회

  //구인 직종 등록
  GET_JOB_POSITION_LIST: [GET, '/job-positions'], //직종목록 조회
  GET_JOB_POSITION_DETAIL_LIST: [GET, '/job-positions/{positionId}'], //세부직종목록 조회
  GET_JOB_OPTION_LIST: [GET, '/job-positions/{positionId}/job-options'], //옵션목록 조회
  GET_EXIST_JOB_POSITION_INFO: [GET, 'job-posts/position'], //구인직종 저장 조회
  SAVE_JOB_POSITION: [POST, '/job-posts/position'], //구인직종 저장
  GET_FEE_LIST: [GET, '/job-positions/position/fee'], //급료 단가표 조회
  //UPLOAD_CATALOG_FILE: [POST, '/files/upload', FORM], //카탈로그 파일 업로드

  //근무 조건 등록
  GET_EXIST_JOB_CONDITION_INFO: [GET, '/job-posts/conditions'], //근무조건 저장 조회
  SAVE_JOB_CONDITION_INFO: [POST, '/job-posts/conditions'], //근무조건 저장

  //상품 목록 조회
  GET_PRODUCT_LIST: [GET, '/products'], //상품 목록 조회
  GET_EXIST_PRODUCT_INFO: [GET, '/job-posts/products'], //선택 상품 저장 조회
  SAVE_PRODUCT_INFO: [POST, '/job-posts/products'], //선택 상품 저장
  GET_PREVIEW_PRODUCT: [GET, '/products/{productCode}'], //미리보기

  //검토 및 수정
  GET_REVIEW_INFO: [GET, '/job-posts/preview'], //검토 및 수정 조회
  SAVE_REVIEW_INFO: [POST, '/job-posts/preview'], //검토 및 수정 저장

  //결제하기
  GET_PAYMENT_INFO: [GET, '/job-posts/payment'], //구매상품, 결제정보 조회
  GET_PAYMENT_RESULT: [GET, '/job-posts/payment/result/{pgLogId}'], // 결제 결과 조회
  GET_COIN_INFO: [GET, '/coins'], //보유 코인 조회
  DO_PAYMENT: [POST, '/job-posts/payment'], //결제하기
  //일자리 등록 끝

  //일자리 찾기
  GET_RECRUIT_LIST: [GET, '/recruits'], //일자리 목록 조회
  GET_BANNER_LIST: [GET, '/hgc/banners'], //배너 목록 조회
  GET_SPECIAL_LIST: [GET, '/hgc/specials'], //스페셜 목록 조회
  GET_URGENT_LIST: [GET, '/hgc/urgents'], //긴급 목록 조회
  GET_GENERAL_LIST: [GET, '/hgc/generals'], //일반 목록 조회
  GET_RECRUIT_DETAIL: [GET, '/recruits/{documentNo}'], //일자리 상세 조회
  POST_APPLY_REQUEST: [POST, '/hgc/jobnego'], //일자리 지원하기
  //일자리 찾기 끝

  //근무요청 시작
  //근무요청
  GET_MATCH_PEOPLE_LIST: [GET, '/job-push/matchPeople'], //근무요청 목록 조회(구인회원)
  GET_REQUEST_LIST_FOR_EMPLOYER: [GET, '/job-push/employer'], //근무요청 목록 조회(구인회원)
  GET_REQUEST_LIST_FOR_EMPLOYEE: [GET, '/job-push/seeker'], //근무요청 목록 조회(구직회원)
  GET_REQUEST_STATUS: [GET, '/job-push/status/{pushId}'], //근무요청 현황 조회(구인/구직회원)
  PUT_REQUEST_STATUS: [PUT, '/job-push/status/{pushId}'], //근무요청 상태 변경(call, 취소)
  //근태관리
  GET_ATTENDANCE_LIST_FOR_EMPLOYER: [GET, '/job-workings/employer'], //근태목록 조회(구인회원)
  GET_ATTENDANCE_LIST_FOR_EMPLOYEE: [GET, '/job-workings/employee'], //근태목록 조회(구직회원)
  GET_ATTENDANCE_DETAIL_FOR_EMPLOYER: [
    GET,
    '/job-workings/{workingId}/employer',
  ], //근태 상세 조회(구인회원)
  GET_ATTENDANCE_DETAIL_FOR_EMPLOYEE: [
    GET,
    '/job-workings/{workingId}/employee',
  ], //근태 상세 조회(구직회원)
  PUT_ATTENDANCE_STATUS: [PUT, '/job-workings/{workingId}/status'], //근태 상태 변경(출퇴근도장, 확인, 노셔, 지급확정/보류)
  PUT_EVAL_ATTENDANCE_TO_EMPLOYER: [
    PUT,
    '/job-workings/{workingId}/eval/toemployer',
  ], //근태 평가(구인/구직 회원, 평가, 지급확정/보류)
  PUT_EVAL_ATTENDANCE_TO_EMPLOYEE: [
    PUT,
    '/job-workings/{workingId}/eval/toemployee',
  ], //근태 평가(구인/구직 회원, 평가, 지급확정/보류)
  //근무요청 끝

  //로그인, 회원가입 시작
  //로그인
  POST_LOGIN_PERSONAL: [POST, '/hga/login', X_FORM], //개인 로그인
  POST_LOGIN_COMPANY: [POST, '/hga/login', X_FORM], //기업 로그인
  POST_LOGOUT: [POST, '/user-info/logout'], //로그아웃
  HOGOO_ENC_KEY: [POST, '/hgc/kmcCrypto/enc'], //본인인증 키 요청
  HOGOO_DEC_KEY: [POST, '/hgc/kmcCrypto/dec'], //본인인증 결과 값 검증 요청
  POST_FCMTOKEN: [POST, '/user-info/fcmtoken'], //fcm token 등록

  //아이디, 비밀번호 찾기
  HOGOO_POST_SEND_SMS_AUTH_NUM: [POST, '/hgc/sms'], //휴대폰 인증번호 발송
  HOGOO_POST_SEND_EMAIL_AUTH_NUM: [POST, '/hgc/email'], //이메일 인증번호 발송
  HOGOO_GET_FIND_ID_WITH_SMS: [POST, '/hgc/sms/id'], //휴대폰으로 아이디 찾기
  HOGOO_GET_FIND_ID_WITH_EMAIL: [POST, '/hgc/email/id'], //이메일로 아이디 찾기
  HOGOO_GET_FIND_PW_WITH_SMS: [POST, '/hgc/sms/password'], //휴대폰으로 비밀번호 찾기
  HOGOO_GET_FIND_PW_WITH_EMAIL: [POST, '/hgc/email/password'], //이메일로 비밀번호 찾기
  //회원가입
  POST_JOIN_PERSONAL: [POST, '/hgc/join/private'], //개인회원 회원가입
  POST_JOIN_COMPANY: [POST, '/hgc/join/company'], //기업회원 회원가입
  HOGOO_GET_IS_DUP_ID: [GET, '/hgc/chkDupId'], // 아이디 중복검사
  HOGOO_GET_IS_DUP_BIZNO: [GET, '/hgc/chkDupBizNo'], // 사업자등록번호 중복검사
  HOGOO_GET_VALID_RECOMMENDER: [GET, '/hgc/chkRecoPhone'], // 추천인 휴대폰번호 존재하는지 검사
  HOGOO_GET_EXIST_BELONG_NAME: [GET, '/hgc/chkCompName'], // 회사 이름 존재하는지 검사

  //마이페이지 시작
  //이력서관리
  //자기소개서
  GET_INTRODUCE_INFO: [GET, '/seeker/cover-letter'], //자기소개서 조회
  SAVE_INTRODUCE_INFO: [POST, '/seeker/cover-letter'], //자기소개서 저장

  //경력사항
  GET_CAREER_LIST: [GET, '/seeker/career'], //경력목록 조회
  //GET_JOB_CATEGORY_CODE_LIST: [GET, '/codes'], //숙박업 코드 목록 조회
  GET_JOB_POSITION_CODE_LIST: [GET, '/job-position/{jobCategoryId}'], //근무직종 코드 목록 조회
  GET_JOB_POSITION_DETAIL_CODE_LIST: [GET, '/job-positions/detail/all'], //근무직종 코드 목록 조회
  SAVE_CAREER_INFO: [POST, '/seeker/careers'], //경력 저장
  DELETE_CAREER_INFO: [DELETE, '/seeker/careers/{careerId}'], //경력 사항 삭제

  //학력사항
  GET_EDU_LIST: [GET, '/seeker/edu'], //학력 목록 조회
  //GET_EDU_CODE_LIST: [GET, '/codes'], //학력 코드 목록 조회
  //GET_EDU_STATUS_CODE_LIST: [GET, '/codes'], //학력 상태 코드 목록 조회
  SAVE_EDU_INFO: [POST, '/seeker/edu'], //학력 저장
  DELETE_EDU_INFO: [DELETE, '/seeker/edu/{eduId}'], //학력 사항 삭제

  //어학  조회 및 저장
  GET_LANGUAGES_LEVEL: [GET, '/seeker/languages'],
  SAVE_LANGUAGES_LEVEL: [POST, '/seeker/languages'],

  //자격증
  GET_CERT_LIST: [GET, '/seeker/cert'], //자격증 목록 조회
  //GET_LANGUAGE_CODE_LIST: [GET, '/codes'], //외국어명 코드 목록 조회
  //GET_COMMUNICATION_LEVEL_CODE_LIST: [GET, '/codes'], //회화능력 코드 목록 조회
  //GET_CERT_TYPE_CODE_LIST: [GET, '/codes'], //자격증 유형 코드 목록 조회
  SAVE_CERT_INFO: [POST, '/seeker/cert'], //자격증 저장
  DELETE_CERT_INFO: [DELETE, '/seeker/cert/{certId}'], //자격증 삭제

  //호구 153 교육 이수관리
  GET_HOGU_EDU_COMPL_LIST: [GET, '/seeker/hg-edu'], //이수 목록 조회
  GET_HOGU_EDU_LIST: [GET, '/seeker/hg-lecture'], //교육과정 목록 조회
  SAVE_HOGU_EDU_INFO: [POST, '/seeker/hg-edu'], //교육신청 저장
  SAVE_HOGU_EDU_COMPL: [POST, '/seeker/hg-edu-compl'], //동영상교육시청 완료 저장
  DELETE_HOGU_EDU_INFO: [DELETE, '/seeker/hogoo-edu/{hgEduId}'], //교육신청 취소

  //기본정보 수정
  GET_BASIC_USER_INFO: [GET, '/user-info/private'], //개인회원 기본정보 조회
  PUT_BASIC_USER_INFO: [PUT, '/user-info/private', FORM], //개인회원 기본정보 수정
  PUT_RESUME_FILE_INFO: [PUT, '/user-info/privateResume', FORM], //개인회원 이력서 파일
  GET_COMPANY_INFO: [GET, '/user-info/company'], //기업회원 기본정보 조회
  PUT_COMPANY_INFO: [PUT, '/user-info/company', FORM], //기업회원 기본정보 수정
  //GET_COUNTRY_CODE_LIST: [GET, '/codes'], //국가 코드 목록 조회
  //GET_VISA_CODE_LIST: [GET, '/codes'], //비자 종류 코드 목록 조회
  GET_SHOP_USER_INFO: [GET, '/user-info/shopUserInfo'], //회원 쇼핑몰연동정보 조회

  //계좌번호
  GET_ACCOUNT_NUM: [GET, '/user-info/account'], //계좌번호 조회
  SAVE_ACCOUNT_NUM: [POST, '/user-info/account'], //계좌번호 저장

  //비밀번호 변경
  // POST_SECURE_LOGIN: [POST, '/user-info/secure-login'], //보안 로그인
  POST_SECURE_LOGIN: [POST, '/user-info/secure-login'], //보안 로그인
  POST_CHANGE_PW: [POST, '/user-info/password'], //비밀번호 변경

  //알림 설정
  GET_NOTIFICATION_CONFIG: [GET, '/user-info/notification'], //알림 설정 조회
  SAVE_NOTIFICATION_CONFIG: [PUT, '/user-info/notification'], //알림 설정 저장

  //회원탈퇴
  POST_WITHDRAW_USER: [PUT, '/user-info/retire'],
  POST_RECOVERY_USER: [PUT, '/user-info/recovery'], // 회원탈퇴 복구
  //스마트 매칭
  GET_SMART_MATCHING_INFO: [GET, '/smart-matching'], //스마트 매칭 조회
  //GET_JOB_CATEGORY_CODE_LIST: [GET, '/codes'], //희망 숙박업 코드 목록 조회 ??중복인지??
  //GET_JOB_POSITION_CODE_LIST: [GET, '/codes}'], //구인 직종 코드 목록 조회 ??중복인지??
  SAVE_SMART_MATHING_INFO: [POST, '/smart-matching'], //스마트 매칭 저장

  //구매내역 조회
  GET_PURCHASE_HISTORY_LIST: [GET, '/sales'], //구매내역 조회
  GET_EMPLOYER_WORK_HISTORY_LIST: [GET, '/job-workings/employer'], //근무내역(구인)
  GET_EMPLOYEE_WORK_HISTORY_LIST: [GET, '/job-workings/employee'], //근무내역(구직)

  //정산내역 조회
  GET_EMPLOYER_CALC_HISTORY_LIST: [GET, '/settlements'], //정산 내역 조회(구인)
  //GET_EMPLOYEE_CALC_HISTORY_LIST: [GET, '/settlements'],  //정산 내역 조회(구직) ??별도인지??

  //포인트 내역 조회
  GET_POINT_HISTORY_LIST: [GET, '/settlements/point/history'], //정산 내역 조회(구인)
  GET_POINT_INFO: [GET, '/settlements/point/info'], // 포인트 정보 조회
  POST_EXCHANGE_POINT: [POST, '/settlements/exchange', FORM],

  //마일리지 내역 조회
  GET_MILEAGE_HISTORY_LIST: [GET, '/settlements/mileage/history'], //정산 내역 조회(구인)
  GET_MILEAGE_INFO: [GET, '/settlements/mileage/info'], // 포인트 정보 조회
  GET_MILEAGE_PERCENT_INFO: [GET, '/settlements/mileage/percent/info'], // 포인트 정보 조회

  // 정산 내역 조회
  GET_SETTLEMENT_LIST_FOR_EMPLOYEE: [GET, '/settlements/payments/employee'],
  GET_SETTLEMENT_LIST_FOR_EMPLOYER: [GET, '/settlements/payments/employer'],
  GET_SETTLEMENT_DETAIL_FOR_EMPLOYEE: [
    GET,
    '/settlements/payments/employee/{workingId}',
  ],
  GET_SETTLEMENT_DETAIL_FOR_EMPLOYER: [
    GET,
    '/settlements/payments/employer/detail',
  ],
  GET_SETTLEMENT_WORKINGS_DETAIL: [
    GET,
    '/settlements/payments/employer/workings',
  ],

  //마이페이지 끝

  //호구153
  GET_MAIN_CONTENTS: [GET, '/main'], //메인 페이지
  GET_MAIN_INTRODUCE: [GET, '/main/intro-hogoo'], //호구 소개
  GET_MAIN_TERMS: [GET, '/main/terms-conditions'], //이용 약관
  GET_MAIN_PRIVACY: [GET, '/main/personal-info-process'], //개인정보 처리 방침
  GET_EMAIL_POLICY: [GET, ''], //이메일 무단수집거부
  POST_HOGOO_PARTNERSHIP: [POST, '/hgc/main/partnership'], //제휴문의

  //충전하기
  GET_COINS_INFO: [GET, '/coin-info'], //충전 정보 조회
  POST_PAY_COINS: [POST, '/coins'], //충전 결제하기

  //기타
  GET_BOARDS_LIST: [GET, '/hgc/Boards'], //공지사항
  GET_UPDATE_READ_CNT: [GET, '/hgc/Boards/{bordNo}'], //공지사항updateReadCnt
  PUT_ONE_ON_ONE_INFO: [PUT, '/hgc/Boards/inquiry'], //1:1문의 등록
  PUT_ONE_ON_ONE_ANSWER: [PUT, '/hgc/Boards/replyInquiry'], //1:1문의 답변 등록
  GET_FAQ_LIST: [GET, '/hgc/faqs'], //이용방법 FAQ목록 조회
  GET_FAQ_DETAIL: [GET, '/hgc/faqs/{faqId}'], //이용방법 상세조회
  GET_NOTIFICATION_LIST: [GET, '/notifications'], //알림목록

  //사용자 아이디 조회
  GET_USER_SESSION_INFO: [POST, '/user-info/id'], //사용자 아이디 조회

  //파일업로드
  POST_FILE_UPLOAD: [POST, '/files/upload', FORM], // 파일업로드

  //이벤트 신청 정보
  GET_EVENTR_INFO: [GET, '/event/eventR'], //이벤트 신청 조회
  PUT_EVENTR_INFO: [PUT, '/event/eventR', FORM], //이벤트 신청
  SAVE_READ_CNT: [GET, '/event/eventM/{eventSeq}'], // 이벤트 읽음 횟수 증가
}
