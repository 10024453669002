<template>
  <div class="wrapper main">
    <!-- <customized-popup v-if="customized"></customized-popup> -->
    <!-- <NewYearPopup
      v-if="popupView && this.$route.name === 'main'"
      @certPopupView="popupView"
      @certClosePop="closePop"
    ></NewYearPopup> -->
    <notice-popup v-if="this.$route.name === 'main'"></notice-popup>
    <touch-popup
      cookie-key="isTouchModal"
      v-if="isApp && this.$route.name === 'main'"
    ></touch-popup>
    <popup-app
      cookie-key="sale"
      v-if="!isApp && this.$route.name === 'main'"
    ></popup-app>
    <main-header></main-header>
    <!-- <section style="min-height: calc(100vh - 330px)">
      <router-view></router-view>
    </section> -->
    <div class="container" ref="container">
      <div class="page-item-wrap" ref="page-item-wrap">
        <router-view @customizedPopupOpen="customizedOpen"></router-view>
      </div>
      <main-footer ref="main-footer"></main-footer>
    </div>
  </div>
</template>

<script>
import MainHeader from '../header/MainHeader'
import MainFooter from '../footer/MainFooter'
import { mapGetters, mapMutations } from 'vuex'
import { setAccessTokenToCookie } from 'Utils/auth'
import { getLoginInfo } from 'Utils/message'
import { getIsApp } from 'Utils/message'
import Cookies from 'js-cookie'
import PopupApp from 'Components/find/popup/PopupApp'
import TouchPopup from 'Components/find/popup/220414_popup'
import NoticePopup from 'Components/community/content/NoticePopup'
// import NewYearPopup from 'Components/popup/NewYearPopup'
// import customizedPopup from 'Components/popup/customizedJobPopup'

export default {
  data() {
    return {
      customized: false,
      popupView: true,
    }
  },
  components: {
    MainHeader,
    MainFooter,
    TouchPopup,
    PopupApp,
    NoticePopup,
    // NewYearPopup,
    // customizedPopup,
  },
  computed: {
    ...mapGetters(['registJobStep']),
  },
  methods: {
    ...mapMutations(['SET_IS_APP']),
    closePop(Val, Yn) {
      this.popupView = Val
      if (Yn === 'Y') {
        this.$cookies.set('NotSeeing', 'Y', '7d')
      }
    },
    onScroll(e) {
      if (
        this.$route.name !== 'main' &&
        this.$route.name !== 'findJob' &&
        this.$route.name !== 'notification'
      )
        return

      const scrollDiff =
        this.$refs['page-item-wrap'].offsetHeight -
        this.$refs['container'].offsetHeight

      this.$eventBus.$emit('container-scroll', {
        scrollDiff,
        scrollTop: e.target.scrollTop,
      })
    },
    customizedOpen(Value) {
      this.customized = Value
    },
    cookieCheck() {
      if (this.$cookies.isKey('NotSeeing')) {
        this.popupView = false
      } else {
        this.popupView = true
      }
    },
  },
  async created() {
    await this.cookieCheck()
    this.$nextTick(() => {
      this.$refs['container'].onscroll = this.onScroll
      if (this.WORK_ROUTE === true) {
        window.$('.container').addClass('work')
      } else {
        window.$('.container').removeClass('work')
      }

      if (this.$route.name == 'main') {
        window.$('.container').addClass('mainCon')
      }
      if (
        this.$route.name == 'sharing' ||
        this.$route.name == 'promotion' ||
        this.$route.name == 'promotionStore'
      ) {
        window.$('header,footer').css('display', 'none')
      } else {
        window.$('header,footer').css('display', 'block')
      }
    })

    //호구앱인지 여부
    const isApp = await getIsApp()
    this.SET_IS_APP(isApp)
    if (isApp && Cookies.get('accessToken') === undefined) {
      const res = await getLoginInfo()
      if (res) {
        this.$store.commit('SET_IS_LOGIN', true)
        this.$store.commit('SET_ACCOUNT_USER_ID', res.userId)
        setAccessTokenToCookie(res.accessToken)
      }
    }
  },
  updated() {
    if (this.WORK_ROUTE === true) {
      window.$('.container').addClass('work')
    } else {
      window.$('.container').removeClass('work')
    }

    if (
      this.$route.name == 'sharing' ||
      this.$route.name == 'promotion' ||
      this.$route.name == 'promotionStore'
    ) {
      window.$('header,footer').css('display', 'none')
    } else {
      window.$('header,footer').css('display', 'block')
    }
  },
}
</script>

<style></style>
