<template>
  <header :style="headerStyle">
    <div class="rela">
      <div class="l-wrap">
        <a
          href="javascript:void(0)"
          class="btn-home"
          @click="$router.push('/')"
        >
          <img src="~assets/image/etc/etc-logo.png" />
        </a>
        <a href="javascript:void(0)" class="btn-prev">
          <i class="el-icon-arrow-left"></i>
        </a>
      </div>
      <button
        class="btn _refresh"
        @click="reload"
        v-if="account.levelNm === '관리자'"
      >
        <span>새로고침</span>
        <i class="ico el-icon-refresh-left"></i>
      </button>
      <div class="gnb-item-wrap" :class="{ on: mobileSearchVisible }">
        <gnb-list></gnb-list>
        <div class="side-item-wrap">
          <search-bar
            v-if="this.$route.path === '/'"
            @search="onSearch"
          ></search-bar>
          <login-profile
            @loginClick="toLoginPage"
            @logout="logout"
          ></login-profile>
        </div>
      </div>
      <mobile-buttons
        :mobileSearchVisible="mobileSearchVisible"
        @openMobileMenu="openMobileMenu"
        @openMobileSearch="openMobileSearch"
      ></mobile-buttons>
    </div>
    <Mobile-Top-Menu></Mobile-Top-Menu>

    <!--모바일 메뉴-->
    <mobile-menu :visible.sync="mobileMenuVisible"></mobile-menu>
  </header>
</template>

<script>
import GnbList from './partials/GnbList'
import SearchBar from './partials/SearchBar'
import LoginProfile from './partials/LoginProfile'
import MobileButtons from './partials/MobileButtons'
import MobileMenu from './partials/MobileMenu'
import MobileTopMenu from './partials/MobileTopMenu'
import { mapMutations, mapGetters } from 'vuex'
import { clearCookie } from 'Utils/auth'
import { postLogout } from 'Api/modules'
import { sendMessageToApp } from 'Utils/message'

export default {
  components: {
    GnbList,
    SearchBar,
    LoginProfile,
    MobileButtons,
    MobileMenu,
    MobileTopMenu,
  },
  data() {
    return {
      mobileMenuVisible: false,
      mobileSearchVisible: false,
    }
  },
  watch: {
    '$route.name'() {
      this.mobileSearchVisible = false
    },
  },
  computed: {
    ...mapGetters(['fcmToken']),
    headerStyle() {
      return this.mobileSearchVisible ? 'z-index: 2;' : 'z-index:auto;'
    },
  },
  methods: {
    ...mapMutations(['SET_IS_LOGIN', 'CLEAR_ACCOUNT_INFO', 'CLEAR_FCM_TOKEN']),
    reload() {
      this.$router.go()
    },
    toLoginPage() {
      this.$router.push('/login')
    },
    async logout() {
      try {
        await postLogout({ fcmToken: this.fcmToken })
        sendMessageToApp('clearKeepLogin')
        this.SET_IS_LOGIN(false)
        this.CLEAR_ACCOUNT_INFO()
        clearCookie()
        this.CLEAR_FCM_TOKEN()
        this.$toasted.show('로그아웃되었습니다.')
        //this.$router.push('/login')
        location.href = '/login'
      } catch (e) {
        console.error(e)
      }
    },
    openMobileMenu() {
      this.mobileMenuVisible = true
    },
    openMobileSearch(visible) {
      this.mobileSearchVisible = visible
    },
    onSearch() {
      this.mobileSearchVisible = false
    },
    menuView() {
      this.mobileMenuVisible = true
    },
  },
}
</script>

<style lang="scss">
header {
  .lnb-login-after .pic {
    background: url(~assets/image/etc/etc-hotel-noimg.svg);
  }
}
</style>
