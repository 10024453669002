<template>
  <div class="r-wrap">
    <a
      href="javascript:void(0)"
      @click="openMobileMenu"
      class="btn-header-allmenu"
      ><img src="~assets/image/ico/ico-totalmenu.svg"
    /></a>
    <a
      class="btn-header-search alim-bell"
      :class="{ on: account.hasNewAlarm === 'Y' }"
      @click="moveToPage('/notification', close)"
      v-if="isLogin"
    >
      <i class="el-icon-bell"></i>
    </a>
    <a
      href="javascript:void(0)"
      class="btn-header-search"
      :class="{
        on,
      }"
      @click="openMobileSearch"
      v-if="this.$route.path === '/'"
    >
      <i class="el-icon-search"></i>
      <i class="el-icon-close"></i>
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    mobileSearchVisible: {
      type: Boolean,
    },
  },
  data() {
    return {
      on: false,
    }
  },
  computed: {
    ...mapGetters(['isLogin', 'account']),
  },
  watch: {
    mobileSearchVisible(newVal) {
      this.on = newVal
    },
  },
  methods: {
    openMobileMenu() {
      this.$emit('openMobileMenu')
    },
    openMobileSearch() {
      this.on = !this.on
      this.$emit('openMobileSearch', this.on)
    },
  },
}
</script>

<style scoped lang="scss">
header .rela .r-wrap {
  flex-direction: row-reverse;
  width: 25%;
}
.alim-bell {
  position: relative;
  border: 0 solid #000;
  background: transparent;
  padding: 0;
}
.alim-bell i {
  font-size: 25px;
}
.alim-bell.on::after {
  content: '';
  display: block;
  width: 4px;
  height: 4px;
  background-color: #ff7b3c;
  border-radius: 4px;
  position: absolute;
  right: 6px;
  top: 13px;
}
</style>
