<template>
  <div
    v-if="show"
    id="pop-app"
    class="dialog pop_container"
    :class="{ on, ing }"
  >
    <div class="pop-wrap type01">
      <!-- <div class="alert-tit">{{ title }}</div> -->
      <div class="alert-msg">
        <!-- <main-job-swiper :list="bannerList"></main-job-swiper> -->
        <img src="~assets/image/main/PopupApp.png" alt="" />
      </div>
      <!-- 안드로이드일 경우 보여지는 btn 앱스토어 주소-->
      <div>
        <button
          v-bind:class="{ on: checkMobile }"
          type="button"
          class="btn-org more-btn"
          @click="onClickgooglePlay()"
        >
          <span>
            {{ moreBtnText }}
            <img src="~assets/image/main/arrow_right.png" alt="" />
          </span>
        </button>
        <!-- 아이폰일 경우 보여지는 btn 플레이스토어 주소-->
        <button
          v-bind:class="{ on: !checkMobile }"
          type="button"
          class="btn-org more-btn"
          @click="onClickAppStore()"
        >
          {{ moreBtnText }}
        </button>
      </div>
      <div class="pop-btn-wrap">
        <!-- <button
          type="button"
          for="today"
          class="btn-gray"
          @click="doTodayClose"
        >
          {{ cancelBtnText }}
        </button> -->
        <button type="button" class="confirm-text" @click="doTodayClose">
          {{ confirmBtnText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import popupMixin from 'Mixins/popup'
import Cookie from 'js-cookie'
import VueCookies from 'vue-cookies'
// import MainJobSwiper from 'Components/find/content/MainJobSwiper'
Vue.use(VueCookies)
Vue.$cookies.config('1d')

// 팝업 쿠키 시간 설정
// 1 : 1초
// 60 * 60 * 12 : 12시간
// 60 * 60 * 25 * 30 : 1달
// 1d : 1일
// new Date(2019,03,13).toUTCString(): 특정 시간

export default {
  mixins: [popupMixin],
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    subDescription: {
      type: String,
    },
    moreBtnText: {
      type: String,
      default: '앱 설치하고 알림받기',
    },
    // cancelBtnText: {
    //   type: String,
    //   default: '하루동안 보지 않기',
    // },
    // confirmBtnText: {
    //   type: String,
    //   default: '닫기',
    // },
    confirmBtnText: {
      type: String,
      default: '괜찮아요, 웹으로 볼게요',
    },
    cookieKey: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      show: false,
      // height: $viewport.height,
    }
  },
  computed: {
    // MobileHeight() {
    //   var mobile = /iphone|ipad|ipod|android/i.test(
    //     navigator.userAgent.toLowerCase(),
    //   )
    //   if (mobile) {
    //     document.querySelector('.wrap').style.height = window.innerHeight + 'px'
    //   }
    //   return false
    // },
    checkMobile() {
      var varUA = navigator.userAgent.toLowerCase() //userAgent 값 얻기
      var mobile = /iphone|ipad|ipod|android/i.test(
        navigator.userAgent.toLowerCase(),
      )
      //console.log(mobile)
      if (mobile && varUA.indexOf('android') > -1) {
        //모바일 처리
        //alert('모바일')
        // alert('안드로이드')
        return false
      } else if (
        mobile &&
        (varUA.indexOf('iphone') > -1 ||
          varUA.indexOf('ipad') > -1 ||
          varUA.indexOf('ipod') > -1)
      ) {
        //alert('아이폰')
        return true
      } else {
        //alert('아이폰')
        //아이폰, 안드로이드 외
        return false
      }
    },
  },
  mounted() {
    //console.log(this.$cookies.isKey('sale'))
    //console.log(Cookie.get(this.cookieKey))
    if (Cookie.get(this.cookieKey) === undefined) {
      //console.log('11111')
      this.show = true
    }
  },
  methods: {
    // onCancelCLick() {
    //   this.$emit('cancel')
    // },
    // onConfirmClick() {
    //   this.$emit('confirm')
    // },
    // 쿠키 셋팅
    doTodayClose() {
      //Cookie.set(this.cookieKey, '1')
      if (this.$cookies.set('sale', 'Y', '1d')) {
        this.show = false
      }
    },
    doClose() {
      this.show = false
    },
    onClickgooglePlay: function () {
      window.open(
        'https://play.google.com/store/apps/details?id=com.hogoo153_app',
        '_blank',
      )
    },
    onClickAppStore: function () {
      window.open(
        'https://apps.apple.com/kr/app/%ED%98%B8%EA%B5%AC153/id1589572390',
        '_blank',
      )
    },
  },
}
// this.$cookies.isKey("sale");
// cookies.key().join('\n')
// cookies.key().forEach(cookies => Cookie.remove(cookies))
function setScreenSize() {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', vh + 'px')
  // console.log(vh)
  // this.height(vh)
}
setScreenSize()
window.addEventListener('resize', setScreenSize)
</script>

<style></style>
