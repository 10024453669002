import api from '../axios'
import { MASTER_CODE } from 'Configs/masterCode'

//자기소개서 조회
export const getIntroduceInfo = async () => {
  // const response = await api('GET_INTRODUCE_INFO', { userId })
  const response = await api('GET_INTRODUCE_INFO')
  return response
}

//자기소개서 저장 test commit
// export const saveIntroduceInfo = async ({ userId, selfIntroduction }) => {
export const saveIntroduceInfo = async ({ selfIntroduction }) => {
  const response = await api('SAVE_INTRODUCE_INFO', {
    selfIntroduction,
  })
  return response
}

//경력 목록 조회
export const getCareerList = async ({ userId }) => {
  const response = await api('GET_CAREER_LIST', { userId })
  return response
}

//숙박업 코드 목록 조회
// export const getJobCategoryCodeList = async ({ mtrCd }) => {
//   const response = await api('GET_JOB_CATEGORY_CODE_LIST', { mtrCd })
//   return response
// }
export const getJobCategoryCodeList = async (mtrCd = MASTER_CODE.AD00069) => {
  const response = await api('GET_CODE_LIST', { mtrCd })
  return response
}

//근무직종 코드 목록 조회
export const getJobPositionCodeList = async ({ jobCategoryId }) => {
  const response = await api('GET_JOB_POSITION_CODE_LIST', { jobCategoryId })
  return response
}

//근무직종 코드 목록 조회
export const getJobPositionDetailCodeList = async () => {
  const response = await api('GET_JOB_POSITION_DETAIL_CODE_LIST')
  return response
}

//경력 목록 저장
export const saveCareerInfo = async ({ careers }) => {
  const response = await api('SAVE_CAREER_INFO', {
    careers,
  })
  return response
}

//경력 사항 삭제
export const deleteCareerInfo = async ({ careerId }) => {
  const response = await api('DELETE_CAREER_INFO', { careerId })
  return response
}

//학력목록 조회
export const getEduList = async ({ userId }) => {
  const response = await api('GET_EDU_LIST', { userId })
  return response
}

//학력코드 목록 조회
// export const getEduCodeList = async ({ mtrCd }) => {
//   const response = await api('GET_EDU_CODE_LIST', { mtrCd })
//   return response
// }
export const getEduCodeList = async (mtrCd = MASTER_CODE.CY00051) => {
  const response = await api('GET_CODE_LIST', { mtrCd })
  return response
}

//학력 상태 코드 목록 조회
// export const getEduStatusCodeList = async ({ mtrCd }) => {
//   const response = await api('GET_EDU_STATUS_CODE_LIST', { mtrCd })
//   return response
// }
export const getEduStatusCodeList = async (mtrCd = MASTER_CODE.CY00050) => {
  const response = await api('GET_CODE_LIST', { mtrCd })
  return response
}

//학력 저장
export const saveEduInfo = async ({ educations }) => {
  const response = await api('SAVE_EDU_INFO', { educations })
  return response
}

//학력 사항 삭제
export const deleteEduInfo = async ({ eduId }) => {
  const response = await api('DELETE_EDU_INFO', { eduId })
  return response
}

//자격증 목록 조회
export const getCertList = async ({ eduId }) => {
  const response = await api('GET_CERT_LIST', { eduId })
  return response
}

//외국어명 코드 목록 조회
// export const getLanguageCodeList = async ({ mtrCd }) => {
//   const response = await api('GET_LANGUAGE_CODE_LIST', { mtrCd })
//   return response
// }
export const getLanguageCodeList = async (mtrCd = MASTER_CODE.CY00020) => {
  const response = await api('GET_CODE_LIST', { mtrCd })
  return response
}

//외국어 능력 조회
export const getLanguagesLevelList = async ({ userId }) => {
  const response = await api('GET_LANGUAGES_LEVEL', { userId })
  return response
}

//외국어능력 저장
export const saveLanguagesLevelList = async ({ languages }) => {
  const response = await api('SAVE_LANGUAGES_LEVEL', { languages })
  return response
}

//회화능력 코드 목록 조회
// export const getCommunicationLevelCodeList = async ({ mtrCd }) => {
//   const response = await api('GET_COMMUNICATION_LEVEL_CODE_LIST', { mtrCd })
//   return response
// }
export const getCommunicationLevelCodeList = async (
  mtrCd = MASTER_CODE.CY00057,
) => {
  const response = await api('GET_CODE_LIST', { mtrCd })
  return response
}

//자격증 유형 코드 목록 조회
// export const getCertTypeCodeList = async ({ mtrCd }) => {
//   const response = await api('GET_CERT_TYPE_CODE_LIST', { mtrCd })
//   return response
// }
export const getCertTypeCodeList = async (mtrCd = '') => {
  const response = await api('GET_CODE_LIST', { mtrCd })
  return response
}

//자격증 저장
export const saveCertInfo = async ({ certs }) => {
  const response = await api('SAVE_CERT_INFO', { certs })
  return response
}

//자격증 삭제
export const deleteCertInfo = async ({ certId }) => {
  const response = await api('DELETE_CERT_INFO', { certId })
  return response
}

//호구153 교육이수 목록 조회
export const getHogoo153EduComplList = async () => {
  const response = await api('GET_HOGU_EDU_COMPL_LIST')
  return response
}

//호구153 교육 정보 리스트
export const getHogoo153EduCourseList = async () => {
  const response = await api('GET_HOGU_EDU_LIST')
  return response
}

//호구153 교육 신청
export const getHogoo153EduApply = async ({ hgEduId }) => {
  const response = await api('SAVE_HOGU_EDU_INFO', { hgEduId })
  return response
}

//호구153 동영상 교육 시청 완료
export const saveHogoo153EduCompl = async ({ hgEduId }) => {
  const response = await api('SAVE_HOGU_EDU_COMPL', { hgEduId })
  return response
}

//호구153 교육 신청 취소
export const deleteHoguEduInfo = async ({ hgEduId }) => {
  const response = await api('DELETE_HOGU_EDU_INFO', { hgEduId })
  return response
}

//교육과정 목록 조회
// export const getCourseCodeList = async ({ mtrCd }) => {
//   const response = await api('GET_COURSE_CODE_LIST', { mtrCd })
//   return response
// }
export const getCourseCodeList = async (mtrCd = '') => {
  const response = await api('GET_CODE_LIST', { mtrCd })
  return response
}

//과목 코드 목록 조회
// export const getSubjectCodeList = async ({ mtrCd }) => {
//   const response = await api('GET_SUBJECT_CODE_LIST', { mtrCd })
//   return response
// }
export const getSubjectCodeList = async (mtrCd = '') => {
  const response = await api('GET_CODE_LIST', { mtrCd })
  return response
}

//교육 이수 목록 조회
export const saveHoguEduInfo = async ({
  userId,
  hgEduId,
  classCode,
  licenseNo,
  classroom,
  majorCode,
  entryDate,
  gradDate,
}) => {
  const response = await api('SAVE_HOGU_EDU_INFO', {
    userId,
    hgEduId,
    classCode,
    licenseNo,
    classroom,
    majorCode,
    entryDate,
    gradDate,
  })
  return response
}
