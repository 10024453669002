import api from '../axios'

//제주도한달살기 조회
export const getEventRInfo = async ({ eventSeq, userId }) => {
  const response = await api('GET_EVENTR_INFO', {
    eventSeq,
    userId,
  })
  return response
}

//제주도 한달살기 저장
export const putEventRInfo = async ({
  eventId,
  eventSeq,
  userId,
  userNm,
  gender,
  phone,
  emergencyContact,
  relation,
  birthday,
  email,
  photo,
  address,
  addressDetail,
  zipcode,
  schoolName,
  schoolGrade,
  uniqueness,
  instagram,
  facebook,
  blog,
  youtube,
  arebeitYn,
  motive,
  privacyYn,
  region,
  period,
  jobPositionNames,
  employmentType,
  payAmt,
  resumeFile,
  resumeFileName,
}) => {
  const response = await api('PUT_EVENTR_INFO', {
    eventId,
    eventSeq,
    userId,
    userNm,
    gender,
    phone,
    emergencyContact,
    relation,
    birthday,
    email,
    photo,
    address,
    addressDetail,
    zipcode,
    schoolName,
    schoolGrade,
    uniqueness,
    instagram,
    facebook,
    blog,
    youtube,
    arebeitYn,
    motive,
    privacyYn,
    region,
    period,
    jobPositionNames,
    employmentType,
    payAmt,
    resumeFile,
    resumeFileName,
  })
  return response
}

//제주도한달살기 읽음 횟수 증가
export const saveReadCnt = async ({ eventSeq }) => {
  const response = await api('SAVE_READ_CNT', {
    eventSeq,
  })
  return response
}
