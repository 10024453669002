export default [
  {
    path: '/',
    component: () => import('Components/community/communityList'),
    meta: { requiresAuth: false },
    children: [
      {
        path: '/',
        name: 'requirement',
        component: () => import('Components/community/content/Requirement'),
        meta: { requiresAuth: false },
      },
      {
        path: '/Notice',
        name: 'notice',
        component: () => import('Components/community/content/Notice'),
        meta: { requiresAuth: false },
      },
      {
        path: '/EventNnews',
        name: 'eventNnews',
        component: () => import('Components/community/content/EventNnews'),
        meta: { requiresAuth: false },
      },
      {
        path: '/Affiliates',
        name: 'affiliates',
        component: () => import('Components/community/content/Affiliates'),
        meta: { requiresAuth: false },
      },
      {
        path: '/OneOnOne',
        name: 'oneOnOne',
        component: () => import('Components/community/content/OneOnOne'),
        meta: { requiresAuth: true },
      },
    ],
  },
]
