export default [
  {
    path: '/mypage/smart-match',
    name: 'mypageSmartMatch',
    component: () => import('Components/myPage/content/SmartMatch.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/mypage/calc-history',
    name: 'mypageCalcHistory',
    component: () => import('Components/myPage/content/CalcHistory.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/mypage/exchange-request',
    name: 'mypageExchangeRequest',
    component: () => import('Components/myPage/content/ExchangeRequest.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/mypage/work-history',
    name: 'mypageWorkHistory',
    component: () => import('Components/myPage/content/WorkHistory.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/mypage/purchase-history',
    name: 'mypagePurchaseHistory',
    component: () => import('Components/myPage/content/PurchaseHistory.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/mypage/point-history',
    name: 'mypagePointHistory',
    component: () => import('Components/myPage/content/PointHistory.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/mypage/mileage-history',
    name: 'mypageMileageHistory',
    component: () => import('Components/myPage/content/MileageHistory.vue'),
    meta: { requiresAuth: true },
  },
]
