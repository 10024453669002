<template>
  <div
    v-if="visible"
    class="user-sub-menu"
    id="userSubMenu"
    @click="$emit('click')"
  >
    <router-link v-if="isEmployee" to="/resume/career" id="Resume"
      >이력서 관리</router-link
    >
    <!--router-link to="/account">계정 설정</!--router-link-->
    <router-link to="/account" id="BasicInfo">기본정보 수정</router-link>
    <router-link v-if="isEmployee" to="/mypage/smart-match" id="SmartMat"
      >스마트매칭</router-link
    >
    <!-- <router-link v-if="isEmployer" to="/mypage/purchase-history"
      >구매내역</router-link
    > -->
    <!--router-link to="/mypage/work-history">근무내역</!--router-link-->
    <router-link to="/mypage/calc-history" id="calculate"
      >정산 내역</router-link
    >
    <router-link to="/mypage/exchange-request" id="currencyExch"
      >환전 요청</router-link
    >
    <router-link to="/mypage/point-history" id="pointBr"
      >포인트 내역</router-link
    >
    <router-link to="/mypage/mileage-history" id="mileageBr"
      >마일리지 내역</router-link
    >
    <router-link
      to="/shoppingMall"
      id="shoppingBr"
      v-if="account.levelNm === '관리자'"
      >복지몰</router-link
    >
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.gnb-item-wrap .side-item-wrap .user-sub-menu {
  display: unset;
  z-index: 10;
}
</style>
