<template>
  <div v-if="loading"></div>

  <!-- 로그인 전 -->
  <div class="user-container" v-else-if="!isLogin && !loading">
    <button type="button" class="btn-login" @click="login">로그인</button>
    <button type="button" class="btn-join" @click="$router.push('/join')">
      회원가입
    </button>
  </div>

  <!-- 로그인 후 -->
  <div class="user-container" v-else-if="isLogin && !loading">
    <button
      type="button"
      class="alim-bell"
      :class="{ on: account.hasNewAlarm === 'Y' }"
      @click="$router.push('/notification')"
      v-if="isPC"
    >
      <i class="el-icon-bell"></i>
    </button>
    <button
      type="button"
      ref="user-profile"
      class="user-profile"
      id="userProfile"
      @click="openUserMenu"
    >
      <img :src="accountUserImg" alt="" class="logo" />
    </button>
    <user-sub-menu
      ref="user-sub-menu"
      :visible="userMenuVisible"
      @click="closeUserMenu"
      @focusout="closeUserMenu"
    ></user-sub-menu>
    <button type="button" class="btn-logout" @click="logout">로그아웃</button>
  </div>
</template>
<script>
import UserSubMenu from './UserSubMenu'
import { mapGetters } from 'vuex'
import { authCheck } from 'Utils/auth'
//import { sendMessageToApp } from 'Utils/message'
//import Cookies from 'js-cookie'

export default {
  components: {
    UserSubMenu,
  },
  data() {
    return {
      loading: true,
      userMenuVisible: false,
    }
  },
  computed: {
    ...mapGetters(['isLogin', 'accountUserImg', 'userId']),
    isPC() {
      return this.isMobileDevice() === 'PC'
    },
  },
  methods: {
    login() {
      this.$emit('loginClick')
    },
    logout() {
      this.$emit('logout')
    },
    openUserMenu() {
      this.userMenuVisible = true
    },
    closeUserMenu() {
      this.userMenuVisible = false
    },
    outsideClickListener(event) {
      if (
        this.$refs['user-sub-menu'] &&
        !this.$refs['user-sub-menu'].$el.contains(event.target) &&
        !this.$refs['user-profile'].contains(event.target) &&
        this.userMenuVisible
      )
        this.userMenuVisible = false
    },
  },
  async created() {
    this.loading = true
    // sendMessageToApp('LOG', {
    //   created: 'LoginProfile',
    //   isApp: Cookies.get('isApp'),
    //   isLogin: Cookies.get('isLogin'),
    //   thisIsLogin: this.isLogin,
    //   userId: Cookies.get('userId'),
    //   thisUserId: this.userId,
    // })
    const res = await authCheck()
    if (res) {
      this.$store.commit('SET_IS_LOGIN', true)
      this.$store.commit('SET_ACCOUNT_INFO', res)
    }
    this.loading = false
    document.addEventListener('click', this.outsideClickListener)
  },
}
</script>

<style lang="scss">
.user-container {
  display: flex;
  #userProfile {
    padding: 0;
    > img {
      height: 100%;
    }
  }
}
</style>
