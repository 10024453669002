export default [
  {
    path: '/resume/introduce',
    name: 'resumeIntroduce',
    component: () => import('Components/resume/content/Introduce.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/resume/career',
    name: 'resumeCareer',
    component: () => import('Components/resume/content/Career.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/resume/graduate',
    name: 'resumeGraduate',
    component: () => import('Components/resume/content/Graduate.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/resume/cert',
    name: 'resumeCert',
    component: () => import('Components/resume/content/Cert.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/resume/lang',
    name: 'resumeLang',
    component: () => import('Components/resume/content/Language.vue'),
    meta: { requiresAuth: true },
  },
]
