export const USER_TYPE = {
  EMPLOYER: 'EMPLOYER',
  EMPLOYEE: 'EMPLOYEE',
  PERSONAL: 'PERSONAL',
  COMPANY: 'COMPANY',
}

export const CUSTOMER_TYPE = [
  // {
  //   name: '일반 회원',
  //   value: '1',
  // },
  {
    name: '가맹점 회원',
    value: '2',
  },
  {
    name: '대리점 회원',
    value: '3',
  },
  {
    name: '협력사 회원',
    value: '4',
  },
  // {
  //   name: 'SMS 회원',
  //   value: 5,
  // },
]

export const CUSTOMER_TYPE_FULL = [
  // {
  //   name: '일반 회원',
  //   value: '1',
  // },
  {
    name: '가맹점 회원',
    value: '2',
    id: 'franchisee',
    text: '호구153 플랫폼 서비스를 통해 구인하고자 하는 기업',
  },
  {
    name: '대리점 회원',
    value: '3',
    id: 'agency',
    text: '호구153 플랫폼 서비스를 이용할 수 있도록 주관사를 대신하여 홍보, 모집, 관리 등의 역할을 대행하는 기업',
  },
  // {
  //   name: 'SMS 회원',
  //   value: 5,
  // },
]

export const JOIN_TYPE = {
  HOGOO: 'HOGOO',
  KAKAO: 'KAKAO',
  NAVER: 'NAVER',
}

export const JOIN_PATH = {
  PC: 'PC',
  MOBILE: 'MOBILE',
}

export const RETENTION_PERIOD = [
  {
    name: '1년',
    value: '1',
  },
  {
    name: '3년',
    value: '2',
  },
  {
    name: '5년',
    value: '3',
  },
  {
    name: '탈퇴 시',
    value: '4',
  },
]

// DB 에 존재하지 않아서 임시로 만듬
export const HOGOO153_CLASS_LIST = [
  {
    code: '1',
    codeValue: '1기 호구 룸메이드 교육',
  },
  {
    name: '2',
    codeValue: '2기 호구 룸메이드 교육',
  },
  {
    name: '3',
    codeValue: '3기 호구 룸메이드 교육',
  },
]

export const HOGOO153_MAJOR_LIST = [
  {
    code: '1',
    codeValue: '룸메이드 기본 교육',
  },
  {
    name: '2',
    codeValue: '룸메이드 심화 교육',
  },
  {
    name: '3',
    codeValue: '베팅 기술',
  },
]
