<template>
  <div class="lnb-login-after">
    <div class="pic" :style="userImg">
      <button type="button" @click="goToAccount">
        <img src="~assets/image/ico/ico-pen.svg" />
      </button>
    </div>
    <div class="scont">
      <div class="info-item">
        <div class="user">{{ account.userNm }}</div>
        <button type="button" @click="logout">로그아웃</button>
      </div>
      <ul>
        <li>
          <div class="key">회원등급</div>
          <div class="value">{{ account.levelNm }}</div>
        </li>
        <li>
          <div class="key">포인트</div>
          <div class="value">
            {{ `${insertCommaToNumber(account.pointBalance)}` }}
          </div>
        </li>
        <!--li>
          <div class="key">코인</div>
          <div class="value">{{ account.coinBalance }}</div>
        </!--li-->
      </ul>
    </div>
    <div class="side-item-wrap">
      <!-- <button type="button" @click="moveToPage('/notification', close)">
        <i class="el-icon-bell"></i>
      </button> -->
      <button type="button" @click="close">
        <i class="el-icon-close"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { clearCookie } from 'Utils/auth'
import { postLogout } from 'Api/modules'
import { mapGetters, mapMutations } from 'vuex'
import { sendMessageToApp } from 'Utils/message'

export default {
  computed: {
    ...mapGetters(['fcmToken', 'accountUserImg']),
    userImg() {
      return `background : url(${
        this.accountUserImg //유저 디폴트 이미지
      })`
    },
  },
  methods: {
    ...mapMutations(['SET_IS_LOGIN', 'CLEAR_ACCOUNT_INFO', 'CLEAR_FCM_TOKEN']),
    close() {
      this.$emit('close')
    },
    goToAccount() {
      this.close()
      this.moveToPage('/account')
    },
    async logout() {
      try {
        await postLogout({ fcmToken: this.fcmToken })
        sendMessageToApp('clearKeepLogin')
        this.SET_IS_LOGIN(false)
        this.CLEAR_ACCOUNT_INFO()
        clearCookie()
        this.close()
        this.CLEAR_FCM_TOKEN()
        this.$toasted.show('로그아웃되었습니다.')
        //if (this.isApp) location.href = '/login'
        //else this.$router.push('/')
        location.href = '/login'
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>

<style></style>
