import api from '../axios'

//메인페이지 일자리 목록 조회
export const getMainContents = async ({ schKeyword }) => {
  const response = await api('GET_MAIN_CONTENTS', { schKeyword })
  return response
}

//호구 소개
export const getMainIntroduce = async () => {
  const response = await ApplicationCache('GET_MAIN_INTRODUCE', {})
  return response
}

//이용약관
export const getMainTerms = async () => {
  const response = await api('GET_MAIN_TERMS', {})
  return response
}

//개인정보 처리 방침
export const getMainPrivacy = async () => {
  const response = await api('GET_MAIN_PRIVACY', {})
  return response
}

//이메일 무단수집거부
export const getEmailPolicy = async () => {
  const response = await api('GET_EMAIL_POLICY', {})
  return response
}

//제휴문의
export const postPartnership = async ({
  name,
  tel,
  email,
  company,
  department,
  position,
  title,
  content,
  companyInfo,
  fileId,
  agreeYn,
}) => {
  const response = await api('POST_HOGOO_PARTNERSHIP', {
    name,
    tel,
    email,
    company,
    department,
    position,
    title,
    content,
    companyInfo,
    fileId,
    agreeYn,
  })
  return response
}

//충전 정보 조회
export const getCoinsInfo = async ({ userId }) => {
  const response = await api('GET_COINS_INFO', { userId })
  return response
}

//충전 결제하기
export const postPayCoins = async ({
  userId,
  coinName,
  coinCode,
  coinPrice,
  accAmt,
}) => {
  const response = await api('POST_PAY_COINS', {
    userId,
    coinName,
    coinCode,
    coinPrice,
    accAmt,
  })
  return response
}

//이용방법 FAQ 목록 조회
export const getFaqList = async ({ schKeyword, pageNumber }) => {
  const response = await api('GET_FAQ_LIST', { schKeyword, pageNumber })
  return response
}

//공지사항 조회
export const getBoardsList = async ({
  schKeyword,
  schFillter,
  pageNumber,
  propCd,
  pageSize,
}) => {
  const response = await api('GET_BOARDS_LIST', {
    schKeyword,
    schFillter,
    pageNumber,
    propCd,
    pageSize,
  })
  return response
}

//공지사항 cnt updated
export const getUpdateReadCnt = async ({ bordNo }) => {
  const response = await api('GET_UPDATE_READ_CNT', { bordNo })
  return response
}

//1:1 문의 내용 등록
export const putOneOnOneInfo = async ({
  notcScop,
  propCd,
  cmpxCd,
  notcTrgt,
  bordCont,
  regUser,
  userNm,
  phone,
  email,
  fileId,
  bordNm,
  // -------
  bordNo,
  deptCd,
  notcWght,
  modDt,
  useYn,
  regDt,
}) => {
  const response = await api('PUT_ONE_ON_ONE_INFO', {
    notcScop,
    propCd,
    cmpxCd,
    notcTrgt,
    bordCont,
    regUser,
    userNm,
    phone,
    email,
    fileId,
    bordNm,
    // -------
    bordNo,
    deptCd,
    notcWght,
    modDt,
    useYn,
    regDt,
  })
  return response
}

//1:1 문의 답변 등록
export const putOneOnOneAnswer = async ({ bordRecont, bordNo, modUser }) => {
  const response = await api('PUT_ONE_ON_ONE_ANSWER', {
    bordRecont,
    bordNo,
    modUser,
  })
  return response
}

//이용방법 상세조회
export const getFaqDetail = async ({ faqId }) => {
  const response = await api('GET_FAQ_DETAIL', { faqId })
  return response
}

//알림 목록
export const getNotificationList = async ({ userId, pageNumber, pageSize }) => {
  const response = await api('GET_NOTIFICATION_LIST', {
    userId,
    pageNumber,
    pageSize,
  })
  return response
}

//사용자 아이디 조회
export const getUserSessionInfo = async () => {
  const response = await api('GET_USER_SESSION_INFO')
  return response
}
