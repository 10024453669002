import { mapGetters } from 'vuex'
import { USER_TYPE } from 'Configs/user'
const mobileExp =
  /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i

export default {
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      windowWid: false,
      //   statusText: '',
    }
  },
  watch: {
    window: {
      deep: true,
      handler() {
        if (this.window.width < 1023) {
          this.windowWid = false
        } else {
          this.windowWid = true
        }
      },
    },
  },
  computed: {
    ...mapGetters(['account', 'isApp']),
    routeName() {
      return this.$route.name
    },
    isEmployee() {
      if (this.account && this.account.userType)
        return this.account.userType === USER_TYPE.EMPLOYEE
      return false
    },
    isEmployer() {
      if (this.account.userType && this.account.userType === USER_TYPE.EMPLOYER)
        return true
      else return false
    },
    // defaultUserImg() {
    //   return this.isEmployee
    //     ? require('assets/image/etc/etc-user-noimg.svg')
    //     : require('assets/image/etc/etc-hotel-noimg.svg')
    // },
    WORK_ROUTE() {
      if (
        this.$route.name == 'requirement' ||
        this.$route.name == 'notice' ||
        this.$route.name == 'eventNnews' ||
        this.$route.name == 'affiliates' ||
        this.$route.name == 'resumeCareer' ||
        this.$route.name == 'accountEdit' ||
        this.$route.name == 'resumeGraduate' ||
        this.$route.name == 'resumeCert' ||
        this.$route.name == 'resumeLang' ||
        this.$route.name == 'resumeIntroduce' ||
        this.$route.name == 'registJob' ||
        this.$route.name == 'accountBank' ||
        this.$route.name == 'accountChangePw' ||
        this.$route.name == 'accountPushSetting' ||
        this.$route.name == 'accountWithdraw'
      ) {
        return true
      } else {
        return false
      }
    },
    NAV_ADDCLASS() {
      if (this.$route.name == 'findJob') {
        return 'findJob'
      } else if (
        this.$route.name == 'requestList' ||
        this.$route.name == 'attendanceList' ||
        this.$route.name == 'mypageCalcHistory'
      ) {
        return 'requestList'
      } else if (
        this.$route.name == 'eduApply' ||
        this.$route.name == 'videoWatch' ||
        this.$route.name == 'resumeEdu'
      ) {
        return 'eduApply'
      } else if (
        this.$route.name == 'requirement' ||
        this.$route.name == 'notice' ||
        this.$route.name == 'eventNnews' ||
        this.$route.name == 'affiliates'
      ) {
        return 'requirement'
      } else if (this.$route.name == 'registJob') {
        return 'registJob'
      } else if (this.$route.name == 'login') {
        return 'login'
      } else if (this.$route.name == 'join') {
        return 'join'
      }
    },
  },
  methods: {
    routePush(Nm) {
      this.$router.push(Nm)
    },
    addZero: function (number) {
      return parseInt(number, 10) < 10 ? '0' + number : number
    },
    moveToPage(route, fn, isNewTab) {
      if (fn) fn()
      if (isNewTab) window.open(route)
      else this.$router.push(route)
    },
    isMobileDevice() {
      const userAgent = navigator.userAgent.toLowerCase() || ''
      if (mobileExp.test(userAgent)) return 'MOBILE'
      else return 'PC'
    },
    insertCommaToNumber(num) {
      return Number(String(num).replace(/[^0-9]/g, '')).toLocaleString('ko-KR')
    },
    insertCurrencyUnit(num) {
      if (num === 0) return '무료'
      else return Number(num).toLocaleString('ko-KR') + '원'
    },
    distanceUnitBrackets(distance) {
      if (distance === '' || distance === undefined) return ''
      else return '[' + Number(distance).toLocaleString('ko-KR') + 'Km]'
    },
    distanceUnit(distance) {
      if (distance === '' || distance === undefined) return ''
      else return Number(distance).toLocaleString('ko-KR') + 'Km'
    },
    splitToArry(addr) {
      const tags = addr.split(' ')
      return tags[0] + ' ' + tags[1]
    },
    removeTag(message) {
      return message.replace(/<br>/g, ' ')
    },
    validSpecial(data) {
      return /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g.test(
        data,
      )
    },
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
  },
}
