const ERROR_CODE = {
  SUCCESS: 0,
  BAD_REQUEST: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  NOT_FOUND_USER: -10,
  INVALID_PASSWORD: -20,
  ACCESS_DENIED: -30,
  METHOD_NOT_ALLOWED: 405,
  REDIRECT: 302,
  SYSTEM_ERROR: -500,
}

export default ERROR_CODE
