import Cookies from 'js-cookie'
import { getUserSessionInfo } from 'Api/modules'
//import { sendMessageToApp } from 'Utils/message'

export const setAccessTokenToCookie = token => {
  Cookies.set('accessToken', token, {
    expires: 30,
    domain: process.env.VUE_APP_COOKIE_DOMAIN,
  })
}

export const authCheck = async () => {
  const token = Cookies.get('accessToken')
  // sendMessageToApp('LOG', {
  //   cookies: 'authCheck send cookies : ' + Cookies.get('accessToken'),
  // })
  //console.info('authCheck => accessToken : ', token)
  if (!token) return null
  else {
    const userInfo = await getUserSessionInfo()
    //console.info('authCheck => userInfo : ', userInfo)
    return userInfo
  }
}

export const clearCookie = () => {
  Cookies.remove('accessToken', {
    expires: -1,
    domain: process.env.VUE_APP_COOKIE_DOMAIN,
  })
  Cookies.remove('fcmToken')
}
