<template>
  <ul>
    <li v-if="isEmployer || !isEmployee">
      <!-- 구인자 회원에게만 노출 -->
      <router-link
        :class="{ on: this.NAV_ADDCLASS === 'registJob' }"
        to="/regist-job"
      >
        일자리 등록
      </router-link>
    </li>
    <li>
      <router-link
        :class="{ on: this.NAV_ADDCLASS === 'findJob' }"
        to="/find-job"
      >
        일자리 찾기
      </router-link>
    </li>
    <li>
      <router-link
        :class="{ on: this.NAV_ADDCLASS === 'requestList' }"
        :to="requestPath"
      >
        근무관리
      </router-link>
    </li>
    <li>
      <router-link
        :class="{ on: this.NAV_ADDCLASS === 'eduApply' }"
        to="/eduApply"
      >
        교육신청
      </router-link>
    </li>
    <li>
      <router-link
        :class="{ on: this.NAV_ADDCLASS === 'requirement' }"
        to="/community"
      >
        커뮤니티
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  computed: {
    requestPath() {
      if (this.isEmployee === true) {
        return '/requestList'
      } else {
        return '/request'
      }
    },
  },
}
</script>

<style></style>
