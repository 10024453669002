<template>
  <hogoo-layout></hogoo-layout>

  <!-- <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
    <HelloWorld msg="Welcome to Your Vue.js App" />
  </div> -->
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import HogooLayout from '@/components/layout/HogooLayout'
import { mapGetters } from 'vuex'
//import { BASE_URL } from 'Api/api'
//import { authCheck } from 'Utils/auth'

export default {
  name: 'Home',
  components: {
    HogooLayout,
    //HelloWorld,
  },
  computed: {
    ...mapGetters(['isLogin', 'account']),
  },
  watch: {
    $route(to, from) {
      if (to.path != from.path) {
        this.$cookies.remove('JobDocument')
      }
    },
    // $route() {
    //   if (
    //     this.account.smMatInfo === 'N' &&
    //     this.$route.name !== 'mypageSmartMatch' &&
    //     this.account.userType === 'EMPLOYEE'
    //   ) {
    //     this.$toasted.error(
    //       '스마트매칭 정보가 없습니다.' +
    //         `<br />` +
    //         '스마트매칭 페이지로 이동합니다.',
    //     )
    //     this.$router.push('/mypage/smart-match')
    //   }
    // },
  },
  // async created() {
  //   setTimeout(() => {
  //     if (
  //       this.account.smMatInfo === 'N' &&
  //       this.$route.name !== 'mypageSmartMatch' &&
  //       this.account.userType === 'EMPLOYEE'
  //     ) {
  //       this.$toasted.error(
  //         '스마트매칭 정보가 없습니다.' +
  //           `<br />` +
  //           '스마트매칭 페이지로 이동합니다.',
  //       )
  //       this.$router.push('/mypage/smart-match')
  //     }
  //   }, 1000)
  //   // const res = await authCheck()
  //   // if (res) {
  //   //   this.$store.commit('SET_IS_LOGIN', true)
  //   //   this.$store.commit('SET_ACCOUNT_INFO', res)
  //   // }
  // },
}
</script>
