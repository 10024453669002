import { REGIST_STEP_NAME } from 'Configs/registJob'

const state = {
  registJobStep: REGIST_STEP_NAME.BASIC_INFO,
  editingDocumentNo: null,
}

const getters = {
  registJobStep: state => state.registJobStep,
  editingDocumentNo: state => state.editingDocumentNo,
}

const mutations = {
  SET_STEP: (state, step) => {
    state.registJobStep = step
  },
  SET_EDITING_DOCUMENT_NO: (state, documentNo) => {
    state.editingDocumentNo = documentNo
  },
  RESET_EDITING_DOCUMENT_NO: state => {
    state.editingDocumentNo = null
  },
}

export default {
  state,
  getters,
  mutations,
}
