import api from '../axios'
import { MASTER_CODE } from 'Configs/masterCode'

//개인회원 기본정보 조회
export const getBasicUserInfo = async () => {
  const response = await api('GET_BASIC_USER_INFO')
  return response
}

//회원 쇼핑몰정보 조회
export const getShopUserInfo = async () => {
  const response = await api('GET_SHOP_USER_INFO')
  return response
}

//개인회원 기본정보 수정
export const putResumeFileInfo = async ({
  userId,
  // userNm,
  resumeNo,
  resumeFileName,
}) => {
  const response = await api('PUT_RESUME_FILE_INFO', {
    userId,
    // userNm,
    resumeNo,
    resumeFileName,
  })
  return response
}

//개인회원 기본정보 수정
export const putBasicUserInfo = async ({
  userId,
  email,
  photo,
  photoUseYn,
  address,
  addressDetail,
  region1DepthName,
  region2DepthName,
  region3DepthName,
  userNm,
  companyNm,
  phone,
  nationality,
  passport,
  foreignerRegNo,
  visaType,
  birthday,
  gender,
  resumeFileName,
}) => {
  const response = await api('PUT_BASIC_USER_INFO', {
    userId,
    email,
    photo,
    photoUseYn,
    address,
    addressDetail,
    region1DepthName,
    region2DepthName,
    region3DepthName,
    userNm,
    companyNm,
    phone,
    nationality,
    passport,
    foreignerRegNo,
    visaType,
    birthday,
    gender,
    resumeFileName,
  })
  return response
}

export const uploadUserImg = async ({ uploadFile }) => {
  const response = await api('POST_FILE_UPLOAD', { uploadFile })
  return response
}

//기업회원 기본정보 조회
export const getCompanyInfo = async ({ userId }) => {
  const response = await api('GET_COMPANY_INFO', { userId })
  return response
}

//기업회원 기본정보 수정
export const putCompanyInfo = async ({
  email,
  photo,
  photoUseYn,
  bizTypeCode,
  bizTypeName,
  userNm,
  bizNo,
  address,
  addressDetail,
  taxbillEmail,
  managerName,
  managerPosition,
  managerEmail,
  managerPhone,
  managerTel,
  managerFax,
  actManagerName,
  actManagerPosition,
  actManagerEmail,
  actManagerPhone,
  actManagerTel,
  actManagerFax,
  companyNm,
  region1DepthName,
  region2DepthName,
  region3DepthName,
}) => {
  const response = await api('PUT_COMPANY_INFO', {
    email,
    photo,
    photoUseYn,
    bizTypeCode,
    bizTypeName,
    userNm,
    bizNo,
    address,
    addressDetail,
    taxbillEmail,
    managerName,
    managerPosition,
    managerEmail,
    managerPhone,
    managerTel,
    managerFax,
    actManagerName,
    actManagerPosition,
    actManagerEmail,
    actManagerPhone,
    actManagerTel,
    actManagerFax,
    companyNm,
    region1DepthName,
    region2DepthName,
    region3DepthName,
  })
  return response
}

//국가 코드 목록 조회
export const getCountryCodeList = async (mtrCd = MASTER_CODE.SY00003) => {
  const response = await api('GET_CODE_LIST', { mtrCd })
  return response
}

//비자 종류 코드 목록 조회
export const getVisaCodeList = async (mtrCd = MASTER_CODE.CY00065) => {
  const response = await api('GET_CODE_LIST', { mtrCd })
  return response
}

//은행 목록 조회
export const getBankCodeList = async (mtrCd = MASTER_CODE.SY00005) => {
  const response = await api('GET_CODE_LIST', { mtrCd })
  return response
}

//계좌번호 조회
export const getAccountNum = async ({ userId }) => {
  const response = await api('GET_ACCOUNT_NUM', { userId })
  return response
}

//계좌번호 저장
export const saveAccountNum = async ({
  userId,
  bankCode,
  bankName,
  actHolder,
  actNumber,
}) => {
  const response = await api('SAVE_ACCOUNT_NUM', {
    userId,
    bankCode,
    bankName,
    actHolder,
    actNumber,
  })
  return response
}

//보안 로그인
export const postSecureLogin = async ({ userId, userPw }) => {
  const response = await api('POST_SECURE_LOGIN', { userId, userPw })
  return response
}

//비밀번호 변경
export const postChangePw = async ({ userId, userPw }) => {
  const response = await api('POST_CHANGE_PW', { userId, userPw })
  return response
}

//알림 설정 조회
export const getNotificationConfig = async ({ userId }) => {
  const response = await api('GET_NOTIFICATION_CONFIG', { userId })
  return response
}

//알림설정 저장
export const saveNotificationConfig = async ({ userId, pushUseYn }) => {
  const response = await api('SAVE_NOTIFICATION_CONFIG', { userId, pushUseYn })
  return response
}

//회원탈퇴
export const postWithdrawUser = async ({ userId, userPw }) => {
  const response = await api('POST_WITHDRAW_USER', { userId, userPw })
  return response
}

//회원탈퇴 복구
export const postRecoveryUser = async ({ userId }) => {
  const response = await api('POST_RECOVERY_USER', { userId })
  return response
}

// export const getBusinesTypeList = async (mtrCd = MASTER_CODE.AD00069) => {
//   const response = await api('GET_CODE_LIST', { mtrCd })
//   return response
// }

//스마트 매칭 조회
export const getSmartMatchingInfo = async ({ userId }) => {
  const response = await api('GET_SMART_MATCHING_INFO', { userId })
  return response
}

//희망 숙박업 코드 목록 조회
export const getJobCategoryCodeList = async (mtrCd = '') => {
  const response = await api('GET_CODE_LIST', { mtrCd })
  return response
}

//구인직종 코드 목록 조회
export const getJobPositionCodeList = async (mtrCd = '') => {
  const response = await api('GET_CODE_LIST', { mtrCd })
  return response
}

//스마트 매칭 저장
export const saveSmartMatchingInfo = async ({
  userId,
  addressAllYn,
  region1Depths,
  region2Depths,
  region3Depths,
  businessTypeAllYn,
  businessTypeCodes,
  businessTypeNames,
  jobPositionIds,
  jobPositionNames,
  jobPositionDtlIds,
  jobPositionDtlNames,
  payAmtMin,
  payAmtMax,
  availDayAllYn,
  availMonYn,
  availTueYn,
  availWedYn,
  availThrYn,
  availFriYn,
  availSatYn,
  availSunYn,
  availWorkingStartTime,
  availWorkingEndTime,
}) => {
  const response = await api('SAVE_SMART_MATHING_INFO', {
    userId,
    addressAllYn,
    region1Depths,
    region2Depths,
    region3Depths,
    businessTypeAllYn,
    businessTypeCodes,
    businessTypeNames,
    jobPositionIds,
    jobPositionNames,
    jobPositionDtlIds,
    jobPositionDtlNames,
    payAmtMin,
    payAmtMax,
    availDayAllYn,
    availMonYn,
    availTueYn,
    availWedYn,
    availThrYn,
    availFriYn,
    availSatYn,
    availSunYn,
    availWorkingStartTime,
    availWorkingEndTime,
  })
  return response
}

//구매내역 조회
export const getPurchaseHistoryList = async ({
  userId,
  startDt,
  endDt,
  pageNumber,
}) => {
  const response = await api('GET_PURCHASE_HISTORY_LIST', {
    userId,
    startDt,
    endDt,
    pageNumber,
  })
  return response
}

//근무내역(구인) 조회
export const getEmployerWorkHistoryList = async ({
  userId,
  workingStartDate,
  workingEndDate,
  page,
}) => {
  const response = await api('GET_EMPLOYER_WORK_HISTORY_LIST', {
    userId,
    workingStartDate,
    workingEndDate,
    page,
  })
  return response
}

//근무내역(구직) 조회
export const getEmployeeWorkHistoryList = async ({
  userId,
  workingStDate,
  workingEdDate,
}) => {
  const response = await api('GET_EMPLOYEE_WORK_HISTORY_LIST', {
    userId,
    workingStDate,
    workingEdDate,
  })
  return response
}

//정산 내역 조회(구인)
export const getEmployerCalcHistoryList = async ({
  userId,
  startDt,
  endDt,
  page,
}) => {
  const response = await api('GET_EMPLOYER_CALC_HISTORY_LIST', {
    userId,
    startDt,
    endDt,
    page,
  })
  return response
}

//포인트 내역 조회
export const getPointHistoryList = async ({
  startDt,
  endDt,
  // expiration,
  pageNumber,
}) => {
  const response = await api('GET_POINT_HISTORY_LIST', {
    startDt,
    endDt,
    // expiration,
    pageNumber,
  })
  return response
}

//포인트 정보 조회
export const getPointInfo = async () => {
  const response = await api('GET_POINT_INFO')
  return response
}

//환전 요청
export const exchangePointToCash = async ({
  pointAmt,
  userNm,
  actNumber,
  bankName,
  bankCode,
}) => {
  const response = await api('POST_EXCHANGE_POINT', {
    pointAmt,
    userNm,
    actNumber,
    bankName,
    bankCode,
  })
  return response
}

//마일리지 내역 조회
export const getMileageHistoryList = async ({ startDt, endDt, pageNumber }) => {
  const response = await api('GET_MILEAGE_HISTORY_LIST', {
    startDt,
    endDt,
    pageNumber,
  })
  return response
}

//마일리지 정보 조회
export const getMileageInfo = async () => {
  const response = await api('GET_MILEAGE_INFO')
  return response
}

//마일리지 등급별 혜택 조회
export const getMileagePercentInfo = async () => {
  const response = await api('GET_MILEAGE_PERCENT_INFO')
  return response
}

// 개인 정산 내역 조회
export const getSettlementListForEmployee = async ({
  startDt,
  endDt,
  page,
}) => {
  const response = await api('GET_SETTLEMENT_LIST_FOR_EMPLOYEE', {
    startDt,
    endDt,
    page,
  })
  return response
}

// 개인 정산 내역 상세 조회
export const getSettlementDetailForEmployee = async ({ workingId }) => {
  const response = await api('GET_SETTLEMENT_DETAIL_FOR_EMPLOYEE', {
    workingId,
  })
  return response
}

// 기업 정산 내역 조회
export const getSettlementListForEmployer = async ({
  startDt,
  endDt,
  pageNumber,
}) => {
  const response = await api('GET_SETTLEMENT_LIST_FOR_EMPLOYER', {
    startDt,
    endDt,
    pageNumber,
  })
  return response
}

// 기업 정산 내역 조회 상세
export const getSettlementDetailForEmployer = async ({
  documentNo,
  // workingDt,
}) => {
  const response = await api('GET_SETTLEMENT_DETAIL_FOR_EMPLOYER', {
    documentNo,
    // workingDt,
  })
  return response
}

export const getSettlementWorkingsDetail = async ({
  documentNo,
  // workingDt,
}) => {
  const response = await api('GET_SETTLEMENT_WORKINGS_DETAIL', {
    documentNo,
    // workingDt,
  })
  return response
}
