export default [
  {
    path: '/account',
    name: 'accountEdit',
    component: () => import('Components/account/content/InfoEdit.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/account/bank',
    name: 'accountBank',
    component: () => import('Components/account/content/Account.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/account/change-pw',
    name: 'accountChangePw',
    component: () => import('Components/account/content/ChangePw.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/account/push-setting',
    name: 'accountPushSetting',
    component: () => import('Components/account/content/PushSetting.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/account/withdraw',
    name: 'accountWithdraw',
    component: () => import('Components/account/content/Withdraw.vue'),
    meta: { requiresAuth: true },
  },
]
