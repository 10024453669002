import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import request from './request'
import account from './account'
import mypage from './mypage'
import resume from './resume'
import community from './community'

import { setAccessTokenToCookie } from 'Utils/auth'
import { getLoginInfo } from 'Utils/message'

import { authCheck, clearCookie } from 'Utils/auth'
//import Cookies from 'js-cookie'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    children: [
      //메인 페이지
      {
        path: '/',
        name: 'main',
        component: () => import('Views/Main'),
        meta: { requiresAuth: false },
      },
      //일자리 찾기
      {
        path: '/find-job',
        name: 'findJob',
        component: () => import('Views/FindJob'),
        meta: { requiresAuth: false },
      },
      //일자리 등록 및 완료
      {
        path: '/regist-job',
        name: 'registJob',
        component: () => import('Views/RegistJob'),
        meta: { requiresAuth: true },
      },
      //모바일에서 접근할 경우 (일자리 등록)
      {
        path: '/regist-job-finish',
        name: 'registJobFinish',
        component: () => import('Components/registJob/content/RegistFinish'),
        meta: { requiresAuth: false },
      },
      {
        path: '/220124_popup',
        name: 'popup',
        component: () => import('Components/common/PopupDefault'),
        meta: { requiresAuth: false },
      },
      //모바일에서 접근할 경우, 결제완료 (마이페이지 정산내역에서)
      {
        path: '/payment-finish',
        name: 'paymentFinish',
        component: () => import('Views/PaymentFinish'),
        meta: { requiresAuth: false },
      },
      //모바일에서 접근할 경우, 결제실패 (마이페이지 정산내역에서)
      {
        path: '/payment-fail',
        name: 'paymentFail',
        component: () => import('Views/PaymentFail'),
        meta: { requiresAuth: false },
      },
      //결제완료 랜딩 페이지
      {
        path: '/payment-complete',
        name: 'paymentComplete',
        component: () => import('Views/PaymentComplete'),
        meta: { requiresAuth: false },
      },
      //일자리 상세정보
      {
        path: '/job-detail/:id',
        name: 'jobDetail',
        component: () => import('Views/JobDetail'),
        meta: { requiresAuth: true },
      },
      //근무 요청
      {
        path: '/request',
        component: () => import('Views/Request.vue'),
        children: [...request],
        meta: { requiresAuth: true },
      },
      //이용 문의
      // {
      //   path: '/requirement',
      //   name: 'requirement',
      //   component: () => import('Views/Requirement'),
      //   meta: { requiresAuth: false },
      // },
      //제휴 문의
      {
        path: '/alience',
        name: 'alience',
        component: () => import('Views/Alience'),
        meta: { requiresAuth: false },
      },
      //포인트 충전
      {
        path: '/point',
        name: 'point',
        component: () => import('Views/Point.vue'),
        meta: { requiresAuth: true },
      },
      //로그인
      {
        path: '/login',
        name: 'login',
        component: () => import('Views/Login.vue'),
        meta: { requiresAuth: false },
      },
      //회원가입
      {
        path: '/join',
        name: 'join',
        component: () => import('Views/Join.vue'),
        meta: { requiresAuth: false },
        props: true,
      },
      //회원가입완료
      {
        path: '/join/result',
        name: 'joinResult',
        component: () => import('Views/JoinResult.vue'),
        meta: { requiresAuth: false },
        props: true,
      },
      //아이디 찾기
      {
        path: '/find-id',
        name: 'findId',
        component: () => import('Views/FindId.vue'),
        meta: { requiresAuth: false },
      },
      //비밀번호 찾기
      {
        path: '/find-pw',
        name: 'findPw',
        component: () => import('Views/FindPw.vue'),
        meta: { requiresAuth: false },
      },
      //마이페이지
      {
        path: '/mypage',
        redirect: '/mypage/smart-match',
        component: () => import('Views/MyPage.vue'),
        children: [...mypage],
        meta: { requiresAuth: true },
      },
      //계정 설정
      {
        path: '/account',
        component: () => import('Views/Account.vue'),
        children: [...account],
        meta: { requiresAuth: true },
      },
      //이력서 관리
      {
        path: '/resume',
        component: () => import('Views/Resume.vue'),
        children: [...resume],
        meta: { requiresAuth: true },
      },
      {
        path: '/community',
        component: () => import('Views/Community.vue'),
        children: [...community],
        meta: { requiresAuth: false },
      },
      {
        path: '/notification',
        name: 'notification',
        component: () => import('Views/Notification.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/compIntroduc',
        name: 'compIntroduc',
        component: () => import('Views/CompIntroduc.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: '/introduce',
        name: 'introduce',
        component: () => import('Views/Introduce.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: '/terms/:viewName',
        name: 'terms',
        component: () => import('Views/Terms.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: '/privacy',
        name: 'privacy',
        component: () => import('Views/Privacy.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: '/email-collect',
        name: 'emailCollect',
        component: () => import('Views/EmailCollect.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: '/video-watch',
        name: 'videoWatch',
        component: () => import('Views/VideoWatch'),
        meta: { requiresAuth: false },
      },
      {
        path: '/app-guide',
        name: 'appGuide',
        component: () => import('Views/AppGuide'),
        meta: { requiresAuth: false },
      },
      {
        path: '/eduApply',
        name: 'eduApply',
        component: () => import('Components/resume/content/EducationApply'),
        meta: { requiresAuth: false },
      },
      {
        path: '/resume/edu',
        name: 'resumeEdu',
        component: () => import('Components/resume/content/Education.vue'),
        meta: { requiresAuth: true },
      },
      //이벤트 신청
      {
        path: '/EventApplication',
        name: 'eventApplication',
        component: () => import('Views/EventApplication'),
        meta: { requiresAuth: true },
      },
      {
        path: '/JejuDetail',
        name: 'jejuDetail',
        component: () => import('Components/application/content/JejuDetail'),
        meta: { requiresAuth: false },
      },
      {
        path: '/JobAppli',
        name: 'jobAppli',
        component: () => import('Components/application/content/JobAppli'),
        meta: { requiresAuth: true },
      },
      {
        path: '/JejuAllTheTime',
        name: 'jejuAllTheTime',
        component: () =>
          import('Components/application/content/JejuAllTheTime'),
        meta: { requiresAuth: true },
      },
      {
        path: '/Jeju2Detail',
        name: 'Jeju2Detail',
        component: () => import('Components/application/content/Jeju2Detail'),
        meta: { requiresAuth: true },
      },
      {
        path: '/Sharing',
        name: 'sharing',
        component: () => import('Views/Sharing.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/Promotion',
        name: 'promotion',
        component: () => import('Views/Promotions.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/PromotionStore',
        name: 'promotionStore',
        component: () => import('Views/PromotionsStore.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: '/ShoppingMall',
        name: 'shoppingMall',
        component: () => import('Views/ShoppingMall.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/SwiperAnimation',
        name: 'swiperAnimation',
        component: () => import('Views/SwiperAnimation.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/MultiTest',
        name: 'multiTest',
        component: () => import('Views/multiTest.vue'),
        meta: { requiresAuth: true },
      },
      // {
      //   path: '/Signature',
      //   name: 'Signature',
      //   component: () => import('Components/common/Signature.vue'),
      //   meta: { requiresAuth: true },
      // },
    ],
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   if (savedPosition) {
  //     const Topdata = JSON.parse(sessionStorage.getItem('mainScroll'))
  //     console.log('index.js', Topdata)

  //     return (document.querySelector('.mainCon').scrollTop = Topdata)
  //   } else {
  //     return (document.querySelector('.container').scrollTop = 0)
  //   }
  // },
  scrollBehavior() {
    document.querySelector('.container').scrollTop = 0
  },
})

//라우터 가드 : 로그인 인증 여부 확인
router.beforeEach(async (to, from, next) => {
  // to and from are both route objects. must call `next`.

  if (to.meta.requiresAuth) {
    const res = await authCheck()
    // sendMessageToApp('LOG', {
    //   message: 'authCheck send message : ' + res,
    //   cookies: 'authCheck send cookies : ' + Cookies.get('accessToken'),
    // })
    if (res) {
      //vuex 세팅
      Vue.$store.commit('SET_IS_LOGIN', true)
      Vue.$store.commit('SET_ACCOUNT_INFO', res)
      next()
    } else {
      //로그아웃 처리, vuex
      Vue.$store.commit('SET_IS_LOGIN', false)
      Vue.$store.commit('CLEAR_ACCOUNT_INFO')
      clearCookie()

      //앱인 경우 로그인 유지 여부를 확인하고 로그인 정보를 받아 세팅한다.
      //@TO THINK : 로그인이 옵션인 페이지인 경우에는 별도로 로그인 처리를 하지 않으므로 문제가 되지 않는지 확인이 필요하다
      if (Vue.$store.getters.isApp) {
        const result = await getLoginInfo()
        // sendMessageToApp('LOG', {
        //   message: 'getLoginInfo retun message : ' + result,
        // })
        if (!result) {
          location.href = '/login?returnUrl=' + to.fullPath
          return
        }

        const { userId, accessToken } = result
        setAccessTokenToCookie(accessToken)

        // sendMessageToApp('LOG', {
        //   message: 'result',
        //   setcookie: Cookies.get('accessToken'),
        // })

        Vue.$store.commit('SET_ACCOUNT_USER_ID', userId)
        Vue.$store.commit('SET_IS_LOGIN', true)
        next()
      } else {
        location.href = '/login?returnUrl=' + to.fullPath
      }

      //next('/login')
    }
  } else next()
})

//라우터 중복 오류 대응 위한 코드
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err
  })
}

export default router
