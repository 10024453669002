import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globalMixin from './mixins/global'
import Toasted from 'vue-toasted'
import VueCookies from 'vue-cookies'
import VueConfirmDialog from 'vue-confirm-dialog'
import VueAnalytics from 'vue-analytics'
// import './utils/fcm'
// import { onMessage } from './utils/fcm'
Vue.use(VueCookies)
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.use(Toasted, { position: 'bottom-center', duration: 5000 })
Vue.use(VueAnalytics, {
  id: 'UA-221099128-1', // 콘솔에서 본인의 키를 확인하세요!
  router, // 원활한 트래킹을 위해 router를 꼭 바인드 해 주어야 합니다!
})
Vue.use(VueAnalytics, {
  id: 'AW-10856866694', // 콘솔에서 본인의 키를 확인하세요!
  router, // 원활한 트래킹을 위해 router를 꼭 바인드 해 주어야 합니다!
})
Vue.mixin(globalMixin)
Vue.config.productionTip = false

const EventBus = new Vue()
Vue.prototype.$eventBus = EventBus

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    Vue.$store = this.$store
    Vue.$router = this.$router
    Vue.$route = this.$route
    Vue.$toasted = this.$toasted
    Vue.$set = this.$set
  },
}).$mount('#app')
