import { URL } from 'Api/api'
import { USER_TYPE } from 'Configs/user'
//import { getIsApp } from 'Utils/message'
import Cookies from 'js-cookie'

const state = {
  isLogin: false,
  account: {},
  userId: '',
  fcmToken: '',
  isApp: false,
  latitude: '',
  longitude: '',
}

const getters = {
  isLogin: state => {
    if (Cookies.get('isLogin')) return Cookies.get('isLogin')
    else return state.isLogin
  },
  latitude: state => {
    if (Cookies.get('latitude')) return Cookies.get('latitude')
    else return state.latitude
  },
  longitude: state => {
    if (Cookies.get('longitude')) return Cookies.get('longitude')
    else return state.longitude
  },
  account: state => state.account,
  userId: state => {
    if (Cookies.get('userId')) return Cookies.get('userId')
    else return state.account.userId
  },
  fcmToken: state => {
    state.fcmToken = Cookies.get('fcmToken') || ''
    return state.fcmToken
  },
  accountUserImg: state => {
    if (!state.account) return require('assets/image/etc/etc-user-noimg.svg')

    if (state.account.userImg)
      return `${URL}/api/hgc/file/view/${state.account.userImg}`
    else
      return state.account.userType === USER_TYPE.EMPLOYEE
        ? require('assets/image/etc/etc-user-noimg.svg')
        : require('assets/image/etc/etc-hotel-noimg.svg')
  },
  isApp: state => {
    if (Cookies.get('isApp')) return Cookies.get('isApp')
    else if (window.ReactNativeWebView) return window.ReactNativeWebView
    else return state.isApp
    // const isApp = await getIsApp()
    // return isApp //window.ReactNativeWebView
  },
}

const mutations = {
  SM_MAT_INFO(state) {
    if (state.account) {
      state.account.smMatInfo = 'Y'
    }
    // state.account.smMatInfo = 'Y'
  },
  SET_IS_LOGIN(state, isLogin) {
    state.isLogin = isLogin
    if (state.isApp && isLogin) Cookies.set('isLogin', true, { expires: 30 })
    else Cookies.remove('isLogin')
  },
  SET_ACCOUNT_USER_ID(state, value) {
    // TODO:: 로그인 -> 로그아웃 후, 재 로그인할때, 토큰을 받으나, state.account 가 null 이라는 에러가 발생.
    if (state.account) {
      state.account.userId = value
    }
    if (state.isApp) Cookies.set('userId', value, { expires: 30 })
    else Cookies.remove('userId')
  },
  SET_ACCOUNT_INFO(state, value) {
    state.account = Object.assign({}, value)
    if (state.isApp) Cookies.set('userId', value.userId, { expires: 30 })
  },
  CLEAR_ACCOUNT_INFO(state) {
    state.account = {}
    Cookies.remove('userId')
  },
  SET_FCM_TOKEN(state, fcmToken) {
    Cookies.set('fcmToken', fcmToken, { expires: 30 })
    state.fcmToken = fcmToken
  },
  CLEAR_FCM_TOKEN(state) {
    Cookies.remove('fcmToken')
    state.fcmToken = ''
  },
  SET_IS_APP(state, isApp) {
    state.isApp = isApp
    if (isApp) Cookies.set('isApp', true, { expires: 30 })
    else Cookies.remove('isApp')
  },
  SET_LATITUDE(state, latitude) {
    Cookies.set('latitude', latitude)
    state.latitude = latitude
  },
  SET_LONGITUDE(state, longitude) {
    Cookies.set('longitude', longitude)
    state.longitude = longitude
  },
}

export default {
  state,
  getters,
  mutations,
}
