<template>
  <div
    v-if="isNoticePopup"
    class="swiper-slide"
    @click="toggleAnswer(NotcPop.bordNo)"
    style="cursor: pointer"
  >
    <div class="banner-item">
      <p class="q2">
        <span v-if="NotcPop.propCd === '1'">&#91;공지사항&#93;</span>
        <span v-if="NotcPop.propCd === '2'">&#91;이벤트 &#38; 뉴스&#93;</span>
        <span>{{ NotcPop.bordNm }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    NotcPop: {
      type: Object,
    },
    ix: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isNoticeWidth: false,
      bordNumber: null,
      window: {
        width: 0,
        height: 0,
      },
      now: new Date(),
      isNoticePopup: true,
    }
  },
  watch: {
    window: {
      deep: true,
      handler() {
        if (this.window.width < 1023) {
          this.isNoticeWidth = false
        } else {
          this.isNoticeWidth = true
        }
      },
    },
  },
  methods: {
    toggleAnswer(bordNo) {
      if (bordNo === 'close') {
        this.bordNumber = null
      } else {
        this.bordNumber = bordNo
      }
      console.log('item', bordNo)
      this.$emit('toggleAnswer', this.bordNumber)
    },
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>

<style></style>
