<template>
  <div
    v-if="isTouchModal"
    id="pop-alert"
    class="dialog pop_container"
    :class="{ on, ing }"
  >
    <div class="pop-wrap type01">
      <!-- <div class="alert-tit">{{ title }}</div> -->
      <div class="alert-msg">
        <img
          src="~assets/image/main/mainPopup-blue-Gr-Width.jpg"
          alt="팝업
        이미지"
          id="mapsPopup"
          usemap="#mapsPopup"
          style="
            height: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          "
        />
        <map name="mapsPopup">
          <area
            alt="회사소개"
            title="회사소개"
            href="/compIntroduc"
            shape="rect"
            coords="80,430,220,590"
            style="outline: none"
            target="_self"
          />
          <area
            alt="앱사용설명서"
            title="앱사용설명서"
            href="/app-Guide"
            shape="rect"
            target="_self"
            coords="295,430,430,590"
            style="outline: none"
          />
          <area
            alt="교육동영상"
            title="교육동영상"
            shape="rect"
            coords="90,635,220,800"
            href="/video-watch"
            style="outline: none"
            target="_self"
            id="app-guide"
          />
          <area
            alt="교육신청"
            title="교육신청"
            shape="rect"
            coords="300,640,430,800"
            href="/eduApply"
            style="outline: none"
            target="_self"
            id="Attendant"
          />
        </map>
        <!-- <main-job-swiper :list="bannerList"></main-job-swiper> -->
      </div>
      <div class="pop-btn-wrap">
        <button
          type="button"
          for="today"
          class="btn-gray"
          @click="doTodayClose"
        >
          {{ cancelBtnText }}
        </button>
        <button type="button" class="btn-org" @click="doClose">
          {{ confirmBtnText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import popupMixin from 'Mixins/popup'
import Cookie from 'js-cookie'
import VueCookies from 'vue-cookies'
// import MainJobSwiper from 'Components/find/content/MainJobSwiper'
Vue.use(VueCookies)
Vue.$cookies.config('1d')

// 팝업 쿠키 시간 설정
// 1 : 1초
// 60 * 60 * 12 : 12시간
// 60 * 60 * 25 * 30 : 1달
// 1d : 1일
// new Date(2019,03,13).toUTCString(): 특정 시간
export default {
  mixins: [popupMixin],
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    subDescription: {
      type: String,
    },
    moreBtnText: {
      type: String,
      default: '회사소개 더 보기',
    },
    cancelBtnText: {
      type: String,
      default: '하루동안 보지 않기',
    },
    confirmBtnText: {
      type: String,
      default: '닫기',
    },
    cookieKey: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      isTouchModal: false,
      // height: $viewport.height,
    }
  },
  mounted() {
    if (Cookie.get(this.cookieKey) === undefined) {
      this.isTouchModal = true
    }
  },
  created() {
    this.$nextTick(() => {
      if (this.$cookies.isKey('TouchModal')) {
        this.isTouchModal = false
      } else {
        this.isTouchModal = true
      }
      window.$('img[usemap]').rwdImageMaps()
    })
  },
  methods: {
    // 쿠키 셋팅
    doTodayClose() {
      if (this.$cookies.set('TouchModal', 'Y', '1d')) {
        this.isTouchModal = false
      }
    },
    doClose() {
      this.isTouchModal = false
    },
  },
}
function setScreenSize() {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', vh + 'px')
  // console.log(vh)
  // this.height(vh)
  var alserMsg = window.$('.alert-msg').height()
  var guideTop = window.$('#guide-top').height() / 2
  let pt = alserMsg - guideTop
  document.documentElement.style.setProperty('--pt', pt + 'px')
  document.documentElement.style.setProperty('--guideTop', guideTop + 'px')
}
setScreenSize()
window.addEventListener('resize', setScreenSize)
</script>

<style></style>
