export default {
  props: {
    visible: {
      type: Boolean,
    },
  },
  data() {
    return {
      on: false,
      ing: false,
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.ing = newVal
        setTimeout(() => {
          this.on = newVal
        }, 200)
      } else {
        this.on = newVal
        setTimeout(() => {
          this.ing = newVal
        }, 500)
      }
    },
  },
  methods: {
    close() {
      this.$emit('update:visible', false)
      this.$emit('close')
    },
  },
}
