export const MASTER_CODE = {
  AD00069: 'AD00069', //숙박업 유형
  AD00061: 'AD00069', //근무기간
  CY00029: 'CY00029', //성별
  AD00072: 'AD00072', //나이
  CY00037: 'CY00037', //나이
  CY00027: 'CY00027', //경력
  AD00065: 'AD00065', //숙식지원
  AD00064: 'AD00064', //4대보험
  AD00067: 'AD00067', //보수, 수당
  AD00068: 'AD00068', //직원복지
  AD00066: 'AD00066', //우대조건
  AD00073: 'AD00073', //개시일시
  AD00071: 'AD00071', //합격결정
  AD00053: 'AD00053', //결제정보
  AD00074: 'AD00074', //결제방법

  AD00075: 'AD00075', //근무요청상태
  //근태상태
  AD00049: 'AD00049', //정보 보유기간
  AD00010: 'AD00010', //기업구분
  CY00051: 'CY00051', //학력사항 분류
  CY00050: 'CY00050', //학력사항 상태
  CY00020: 'CY00020', //어학 외국어명
  CY00057: 'CY00057', //회화능력
  CY00011: 'CY00011', //출근요일
  CY00023: 'CY00023', //국가
  CY00065: 'CY00065', //비자종류
  SY00005: 'SY00005', //은행명

  AD00077: 'AD00077', //회원유형
  AD00078: 'AD00078', //구인상태
  AD00079: 'AD00079', //문서상태

  SY00003: 'SY00003', //국적목록

  BD00004: 'BD00004', //1:1문의 서비스 타입
}
