import api from '../axios'
import { MASTER_CODE } from 'Configs/masterCode'

export const getAdvertiSementList = async ({
  userId,
  schKeyword,
  reqStartDt,
  reqEndDt,
}) => {
  const response = await api('GET_ADVERTI_SEMENT_LIST', {
    userId,
    schKeyword,
    reqStartDt,
    reqEndDt,
  })
  return response
}

//1.이전 공고 목록 조회
/*
  list.documentNo
  list.jobPostTitle
  list.regDt
  list.recruitingStatus
*/
export const getPreviousRecruitsList = async ({ userId }) => {
  const response = await api('GET_PREVIOUS_RECRUITS_LIST', { userId })
  return response
}

//2.이전 공고 수정 : documentNo의 수정 가능 여부(editable)를 응답받음
/* documentNo */
export const editPreviousRecruit = async ({ documentNo }) => {
  const response = await api('EDIT_PREVIOUS_RECRUIT', { documentNo })
  return response
}

//3.이전 공고 복사 : 복사한 documentNo를 응답받음
/* documentNo */
export const copyPreviousRecruit = async ({ documentNo }) => {
  const response = await api('COPY_PREVIOUS_RECRUIT', { documentNo })
  return response
}

//이전 공고 삭제
export const deletePreviousRecruit = async ({ documentNo }) => {
  const response = await api('DELETE_PREVIOUS_RECRUIT', { documentNo })
  return response
}

//4. 기존 주소 조회
/*
  workingAddress
  workingAddressDetail
  region1DepthName
  region2DepthName
  region3DepthName
*/
export const getExistAddress = async ({ userId }) => {
  const response = await api('GET_EXIST_ADDRESS', { userId })
  return response
}

//숙박업 유형 조회
/*
  mtrCd
  mtrCdNm
  dtlCd
  dtlCdFullNm
*/
// export const getBusinesTypeList = async (mtrCd = 'CY00039') => {
//   const response = await api('GET_BUSINESS_TYPE_LIST', { mtrCd })
//   return response
// }
export const getBusinesTypeList = async (mtrCd = MASTER_CODE.AD00069) => {
  const response = await api('GET_CODE_LIST', { mtrCd })
  return response
}

//근무지 저장
export const saveJobLocation = async ({
  documentNo,
  userId,
  jobPostTitle,
  hotelName,
  workingAddress,
  workingAddressDetail,
  region1DepthName,
  region2DepthName,
  region3DepthName,
  businessTypeCode,
  businessTypeName,
  attachedFileName,
  catalogId,
}) => {
  const response = await api('SAVE_JOB_LOCATION', {
    documentNo,
    userId,
    jobPostTitle,
    hotelName,
    workingAddress,
    workingAddressDetail,
    region1DepthName,
    region2DepthName,
    region3DepthName,
    businessTypeCode,
    businessTypeName,
    attachedFileName,
    catalogId,
  })
  return response
}
//근무지 저장 조회
export const getExistJobLocation = async ({ documentNo }) => {
  const response = await api('GET_EXIST_JOB_LOCATION', { documentNo })
  return response
}

//직종목록 조회
export const getJobPositionList = async () => {
  const response = await api('GET_JOB_POSITION_LIST')
  return response
}
//세부 직종목록 조회
export const getJobPositionDetailList = async ({ positionId }) => {
  const response = await api('GET_JOB_POSITION_DETAIL_LIST', {
    positionId,
  })
  return response
}
//옵션목록 조회
export const getJobOptionList = async ({ positionId }) => {
  const response = await api('GET_JOB_OPTION_LIST', { positionId })
  return response
}
//구인직종 저장 조회
export const getExistJobPositionInfo = async ({ documentNo }) => {
  const response = await api('GET_EXIST_JOB_POSITION_INFO', { documentNo })
  return response
}
//구인직종 저장
export const saveJobPosition = async jobPosition => {
  const response = await api('SAVE_JOB_POSITION', jobPosition)
  return response
}
//급료 단가표 조회
export const getFeeList = async ({ schKeyword }) => {
  const response = await api('GET_FEE_LIST', { schKeyword })
  return response
}
//카탈로그 파일 업로드
export const uploadCatologFile = async ({ uploadFile }) => {
  const response = await api('POST_FILE_UPLOAD', { uploadFile })
  return response
}

//근무조건 저장 조회
export const getExistJobConditionInfo = async ({ documentNo }) => {
  const response = await api('GET_EXIST_JOB_CONDITION_INFO', { documentNo })
  return response
}
//근무조건 저장
export const saveJobConditionInfo = async ({
  documentNo,
  jobConditionId,
  genderCode,
  ageCode,
  ageMin,
  ageMax,
  careerCode,
  nationality,
  foreignLanguage,
  supportOptionCodes,
  majorInsuranceCodes,
  incentiveCodes,
  welfareCodes,
  spcCondCodes,
  note,
}) => {
  const response = await api('SAVE_JOB_CONDITION_INFO', {
    documentNo,
    jobConditionId,
    genderCode,
    ageCode,
    ageMin,
    ageMax,
    careerCode,
    nationality,
    foreignLanguage,
    supportOptionCodes,
    majorInsuranceCodes,
    incentiveCodes,
    welfareCodes,
    spcCondCodes,
    note,
  })
  return response
}

//상품목록 조회
export const getProductList = async ({ period }) => {
  const response = await api('GET_PRODUCT_LIST', { period })
  return response
}
//선택 상품 저장 조회
export const getExistProductInfo = async ({ documentNo }) => {
  const response = await api('GET_EXIST_PRODUCT_INFO', { documentNo })
  return response
}
//선택 상품 저장
export const saveProductInfo = async ({
  documentNo,
  boxProdId,
  listupProdId,
  pushProdId,
  emphasisProdIds,
  period,
  openingTypeCode,
  openingReservationDt,
  closingDt,
  passDecisionType,
  passDecisionTime,
}) => {
  const response = await api('SAVE_PRODUCT_INFO', {
    documentNo,
    boxProdId,
    listupProdId,
    pushProdId,
    emphasisProdIds,
    period,
    openingTypeCode,
    openingReservationDt,
    closingDt,
    passDecisionType,
    passDecisionTime,
  })
  return response
}
//미리보기
export const getPreviewProduct = async ({ productCode }) => {
  const response = await api('GET_PREVIEW_PRODUCT', { productCode })
  return response
}

// 결제 결과 조회
export const getPaymentResult = async ({ pgLogId }) => {
  const response = await api('GET_PAYMENT_RESULT', { pgLogId })
  return response
}

//검토 및 수정 조회
export const getReviewInfo = async ({ documentNo }) => {
  const response = await api('GET_REVIEW_INFO', { documentNo })
  return response
}
//검토 및 수정 저장
export const saveReviewInfo = async ({
  documentNo,
  jobManager,
  managerPhone,
}) => {
  const response = await api('SAVE_REVIEW_INFO', {
    documentNo,
    jobManager,
    managerPhone,
  })
  return response
}

//구매상품, 결제정보 조회
export const getPaymentInfo = async ({ documentNo }) => {
  const response = await api('GET_PAYMENT_INFO', { documentNo })
  return response
}
//보유코인 조회
export const getCoinInfo = async ({ userId }) => {
  const response = await api('GET_COIN_INFO', { userId })
  return response
}
//결제하기
export const doPayment = async ({
  documentNo,
  paymentAmt,
  coinUseAmt,
  userId,
  paymentType,
}) => {
  const response = await api('DO_PAYMENT', {
    documentNo,
    paymentAmt,
    coinUseAmt,
    userId,
    paymentType,
  })
  return response
}

// 국적코드 목록 조회
export const getNationalities = async (mtrCd = MASTER_CODE.SY00003) => {
  const response = await api('GET_CODE_LIST', { mtrCd })
  return response
}

// 외국어코드 목록 조회
export const getForeignLanguages = async (mtrCd = MASTER_CODE.CY00020) => {
  const response = await api('GET_CODE_LIST', { mtrCd })
  return response
}
