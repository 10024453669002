export default [
  {
    path: '/requestList',
    component: () => import('Components/request/JobRequest'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        name: 'requestList',
        component: () => import('Components/request/content/JobRequestList'),
        meta: { requiresAuth: true },
      },
      {
        path: '/request/:reqId',
        name: 'requestDetail',
        component: () => import('Components/request/content/JobRequestDetail'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/attendance',
    component: () => import('Components/request/Attendance'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        name: 'attendanceList',
        component: () => import('Components/request/content/AttendanceList'),
        meta: { requiresAuth: true },
      },
      {
        path: '/attendance/:jobId',
        name: 'attendanceRate',
        component: () => import('Components/request/content/AttendanceRate'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/',
    component: () => import('Components/request/JobDocument'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        name: 'JobDocumentList',
        component: () => import('Components/request/content/JobDocumentList'),
        meta: { requiresAuth: true },
      },
    ],
  },
]
