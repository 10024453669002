<template>
  <div class="search-item-wrap">
    <input
      v-model="schKeyword"
      type="text"
      class="inp"
      placeholder="근무처, 근무내용 검색"
      @keyup.enter="search"
    />
    <button type="button" @click="search">
      <i class="el-icon-search"></i>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      schKeyword: '',
    }
  },
  watch: {
    '$route.query.schKeyword': {
      immediate: true,
      handler(newVal) {
        this.schKeyword = newVal
      },
    },
  },
  methods: {
    search() {
      const query = this.schKeyword
        ? {
            schKeyword: this.schKeyword,
          }
        : null
      this.$router.push({
        name: 'findJob',
        query,
      })
      this.$emit('search')
    },
  },
}
</script>

<style></style>
