//리엑트 네이티브 앱에 메시지 전송
export const sendMessageToApp = (method, data) => {
  const message = {
    method,
    data,
  }

  if (window.ReactNativeWebView)
    window.ReactNativeWebView.postMessage(JSON.stringify(message))
}

export const getLoginInfo = async () => {
  return new Promise(res => {
    if (!window.ReactNativeWebView) {
      res(false)
    } else {
      //로그인 정보를 구한다.
      sendMessageToApp('getLoginInfo')

      const eventHandler = message => {
        try {
          const messageData = JSON.parse(message.data)
          if (messageData.name !== 'HOGOO') res(false)
          clearInterval(intervalId)
          // sendMessageToApp('LOG', {
          //   message:
          //     'getLoginInfo recieved from app : ' + messageData.data.result,
          // })
          window.removeEventListener('message', eventHandler)
          document.removeEventListener('message', eventHandler)
          if (messageData.data.result) {
            const { userId, accessToken } = messageData.data
            res({ userId, accessToken })
          } else {
            res(false)
          }
        } catch (e) {
          console.error('[loginInfo]not app message')
          res(false)
        }
      }
      window.addEventListener('message', eventHandler)
      document.addEventListener('message', eventHandler)
      let count = 0
      const intervalId = setInterval(() => {
        if (count < 6) count++
        else {
          clearInterval(intervalId)
          res(false)
        }
      }, 1000)
    }
  })
}

export const getIsApp = async () => {
  return new Promise(res => {
    if (!window.ReactNativeWebView) {
      res(false)
    } else {
      sendMessageToApp('getIsApp')

      const eventHandler = message => {
        try {
          const messageData = JSON.parse(message.data)
          if (messageData.name !== 'HOGOO') res(false)
          clearInterval(intervalId)
          // sendMessageToApp('LOG', {
          //   message: 'recieved from app : ' + messageData.data.isApp,
          // })

          window.removeEventListener('message', eventHandler)
          document.removeEventListener('message', eventHandler)

          res(messageData.data.isApp)
        } catch (e) {
          console.error('[getIsApp]not app message')
          res(false)
        }
      }

      window.addEventListener('message', eventHandler)
      document.addEventListener('message', eventHandler)

      let count = 0
      const intervalId = setInterval(() => {
        if (count < 4) count++
        else {
          clearInterval(intervalId)
          res(false)
        }
      }, 1000)
    }
  })
}

//리엑트 네이티브 앱에서 FCM 토큰 요청 및 수신
export const getFCMToken = async () => {
  return new Promise(res => {
    if (!window.ReactNativeWebView) {
      res('')
    } else {
      sendMessageToApp('getToken')

      const eventHandler = message => {
        try {
          const messageData = JSON.parse(message.data)
          if (messageData.name !== 'HOGOO') res(false)
          // sendMessageToApp('LOG', {
          //   message: 'fcmtoken',
          //   getToken: messageData.data.fcmToken,
          // })
          clearInterval(intervalId)
          window.removeEventListener('message', eventHandler)
          document.removeEventListener('message', eventHandler)
          res(messageData.data.fcmToken)
        } catch (e) {
          console.error('[getFcmToken]not app message')
          res('')
        }
      }
      //token 수신
      window.addEventListener('message', eventHandler)
      document.addEventListener('message', eventHandler)
      //기기가 응답이 없는 경우
      let count = 0
      const intervalId = setInterval(() => {
        if (count < 4) count++
        else {
          clearInterval(intervalId)
          res('')
        }
      }, 1000)
    }
  })
}

export const searchAddressGetResult = async callback => {
  sendMessageToApp('searchAddress')

  const eventHandler = message => {
    try {
      const messageData = JSON.parse(message.data)
      if (messageData.name !== 'HOGOO') return

      window.removeEventListener('message', eventHandler)
      document.removeEventListener('message', eventHandler)

      if (messageData.data.noData) return

      const { sido, sigungu, bname, address } = messageData.data
      callback({ sido, sigungu, bname, address })
      return
    } catch (e) {
      console.error('not app message')
      return
    }
  }

  window.addEventListener('message', eventHandler)
  document.addEventListener('message', eventHandler)
}
