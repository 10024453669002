import { TERM_LIST } from './filter'

export const REGIST_STEP_NAME = {
  BASIC_INFO: 'BASIC_INFO',
  JOB_CATEGORY: 'JOB_CATEGORY',
  JOB_CONDITION: 'JOB_CONDITION',
  PRODUCT_SELECT: 'PRODUCT_SELECT',
  CHECK_EDIT: 'CHECK_EDIT',
  PAYMENT: 'PAYMENT',
  REGIST_FINISH: 'REGIST_FINISH',
}

export const REGIST_STEP_ARRAY = [
  REGIST_STEP_NAME.BASIC_INFO,
  REGIST_STEP_NAME.JOB_CATEGORY,
  REGIST_STEP_NAME.JOB_CONDITION,
  REGIST_STEP_NAME.PRODUCT_SELECT,
  REGIST_STEP_NAME.CHECK_EDIT,
  REGIST_STEP_NAME.PAYMENT,
  REGIST_STEP_NAME.REGIST_FINISH,
]

export const REGIST_STEP_INFO = {
  BASIC_INFO: {
    title: '기본정보',
    description: '일자리를 제공하는 기본 정보를 등록할 수 있습니다.',
  },
  JOB_CATEGORY: {
    title: '구인 직종',
    description: '일자리에 필요한 구인직종을 등록할 수 있습니다.',
  },
  JOB_CONDITION: {
    title: '근무 조건',
    description: '일자리에 필요한 근무 조건을 등록할 수 있습니다.',
  },
  PRODUCT_SELECT: {
    title: '상품선택',
    description: '일자리를 화면에 노출시키기 위한 정책을 선택할 수 있습니다.',
  },
  CHECK_EDIT: {
    title: '검토 및 수정',
    description: '일자리 등록전에 기입하신 정보를 최종 확인하세요.',
  },
  PAYMENT: {
    title: '결제하기',
    description: '구매하신 상품을 확인하시고 결제하실 수 있습니다',
  },
  REGIST_FINISH: {
    title: '일자리 등록 완료',
    description: '',
  },
}

export const RECRUITING_STATUS = {
  CLOSE: '모집마감',
  CONFIRMED: '구인확정',
  DONE: '거래완료',
  RECRUITING: '모집중',
  RESERVATION: '예약',
  WORKING: '근무중',
  CONTEMPORARY: '작성중',
  NORMAL: '작성완료',
}

export const BUSINESS_TYPE_OPTION = [
  { title: '5성급 호텔', value: 'FH' },
  { title: '4성급 호텔', value: 'FOH' },
  { title: '3성급 호텔', value: 'TH' },
  { title: '2성급 호텔', value: 'TWH' },
  { title: '1성급 호텔', value: 'OH' },
  { title: '모텔', value: 'MOTEL' },
  { title: '펜션', value: 'PENSION' },
  { title: '비즈니스 호텔', value: 'BUSINESS' },
  { title: '호스텔', value: 'HOSTEL' },
  { title: '리조트', value: 'RESORT' },
]

export const TERM_OPTION = [
  { name: '단기형', value: TERM_LIST[0].value },
  { name: '장기형', value: TERM_LIST[1].value },
  { name: '테마형', value: TERM_LIST[2].value },
]

export const TERM_OPTION_KEY_VALUE = {
  [TERM_LIST[0].value]: '단기형',
  [TERM_LIST[1].value]: '장기형',
  [TERM_LIST[2].value]: '테마형',
}

export const GENDER_OPTION = [
  { name: '성별무관', on: true, value: '2' },
  { name: '남성', on: false, value: '0' },
  { name: '여성', on: false, value: '1' },
  //{ name: '기타', on: false, value: 'ETC' },
]

export const OPTION_GENDER = [
  { name: '남성', on: false, value: '0' },
  { name: '여성', on: false, value: '1' },
  //{ name: '기타', on: false, value: 'ETC' },
]

export const AGE_OPTION = [
  { name: '나이무관', on: true, value: 'NONE' },
  { name: '나이제한', on: false, value: 'RESTRICT' },
]

//18~80세
let res = []
export const AGE_RANGE = () => {
  if (res.length === 0) {
    res = []
    for (let i = 18; i < 81; i++) {
      res.push(i)
    }
  }
  return res
}

export const FOREIGN_OPTION = [
  { name: '영어', on: false, value: '1' },
  { name: '일본어', on: false, value: '2' },
  { name: '중국어', on: false, value: '3' },
  { name: '태국어', on: false, value: '4' },
  { name: '기타', on: false, value: '5' },
]

export const CAREER_OPTION = [
  { name: '경력무관', on: true, value: 'NONE' },
  { name: '신입', on: false, value: 'NEWCOMER' },
  { name: '경력', on: false, value: 'CAREER' },
]

export const SUPPORT_OPTION = [
  { name: '숙소제공', on: false, value: 'AP' },
  { name: '숙식제공', on: false, value: 'PA' },
  { name: '식사제공', on: false, value: 'SM' },
  { name: '출퇴근', on: false, value: 'CW' },
]

export const INSURANCE_OPTION = [
  { name: '국민연금', on: false, value: 'NP' },
  { name: '건강보험', on: false, value: 'HI' },
  { name: '고용보험', on: false, value: 'EI' },
  { name: '산재보험', on: false, value: 'IAI' },
]

export const SALARY_OPTION = [
  { name: '인센티브제', on: false, value: 'INSENTIVE' },
  { name: '정기보너스', on: false, value: 'BONUS' },
  { name: '휴가비지원', on: false, value: 'VACATION' },
  { name: '퇴직금', on: false, value: 'RETIRING' },
]

export const WELFARE_OPTION = [
  { name: '경조금', on: false, value: 'OF' },
  { name: '경조휴가제', on: false, value: 'OV' },
  { name: '건강검진', on: false, value: 'HC' },
  { name: '정기휴가', on: false, value: 'RV' },
  { name: '연차', on: false, value: 'AV' },
]

export const TREAT_OPTION = [
  { name: '운전면허', on: false, value: 'DL' },
  { name: '방화관리자', on: false, value: 'FM' },
  { name: '가스안전관리자', on: false, value: 'GM' },
  { name: '승강기안전관리자', on: false, value: 'LM' },
  { name: 'HOGOO153 교육이수', on: false, value: 'HG' },
]

export const RELEASE_OPTION = [
  { name: '즉시개시', on: true, value: 'DIRECT' },
  { name: '예약개시', on: false, value: 'RESERVATION' },
]

export const RELEASE_OPTION_VALUE = {
  DIRECT: '즉시개시',
  RESERVATION: '예약개시',
}

export const RECRUIT_RESULT_OPTION = [
  { name: '즉시결정', value: 'I' },
  { name: '일괄결정', value: 'B' },
]

export const RECRUIT_RESULT_OPTION_KEY_VALUE = {
  I: '즉시결정',
  B: '일괄결정',
}

export const PAY_TYPE_OPTION = [
  { name: '선불', on: true, value: 1 },
  { name: '후불', on: false, value: 2 },
]

export const PAY_METHOD_OPTION = [
  { name: '간편결제', on: true, value: 'SIMPLE' },
  { name: '기타결제', on: false, value: 'ETC' },
]

// 근무시간 목록
export const WORK_TIME = {
  '00:00': '00:00',
  '00:30': '00:30',
  '01:00': '01:00',
  '01:30': '01:30',
  '02:00': '02:00',
  '02:30': '02:30',
  '03:00': '03:00',
  '03:30': '03:30',
  '04:00': '04:00',
  '04:30': '04:30',
  '05:00': '05:00',
  '05:30': '05:30',
  '06:00': '06:00',
  '06:30': '06:30',
  '07:00': '07:00',
  '07:30': '07:30',
  '08:00': '08:00',
  '08:30': '08:30',
  '09:00': '09:00',
  '09:30': '09:30',
  '10:00': '10:00',
  '10:30': '10:30',
  '11:00': '11:00',
  '11:30': '11:30',
  '12:00': '12:00',
  '12:30': '12:30',
  '13:00': '13:00',
  '13:30': '13:30',
  '14:00': '14:00',
  '14:30': '14:30',
  '15:00': '15:00',
  '15:30': '15:30',
  '16:00': '16:00',
  '16:30': '16:30',
  '17:00': '17:00',
  '17:30': '17:30',
  '18:00': '18:00',
  '18:30': '18:30',
  '19:00': '19:00',
  '19:30': '19:30',
  '20:00': '20:00',
  '20:30': '20:30',
  '21:00': '21:00',
  '21:30': '21:30',
  '22:00': '22:00',
  '22:30': '22:30',
  '23:00': '23:00',
  '23:30': '23:30',
}

// 게시 시간
export const RELEASE_TIME = {
  '00:00': '00:00',
  '01:00': '01:00',
  '02:00': '02:00',
  '03:00': '03:00',
  '04:00': '04:00',
  '05:00': '05:00',
  '06:00': '06:00',
  '07:00': '07:00',
  '08:00': '08:00',
  '09:00': '09:00',
  '10:00': '10:00',
  '11:00': '11:00',
  '12:00': '12:00',
  '13:00': '13:00',
  '14:00': '14:00',
  '15:00': '15:00',
  '16:00': '16:00',
  '17:00': '17:00',
  '18:00': '18:00',
  '19:00': '19:00',
  '20:00': '20:00',
  '21:00': '21:00',
  '22:00': '22:00',
  '23:00': '23:00',
}
