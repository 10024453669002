<template>
  <div
    v-show="bordNumber === NotcDetail.bordNo"
    class="notice-Popup-Body"
    :class="{ on: bordNumber === NotcDetail.bordNo }"
    :id="`NotcDetail_${ix}`"
  >
    <div class="a noticeDetail">
      <div class="bordCont-head">
        <p class="noticeDetail-title" v-html="NotcDetail.bordNm"></p>
        <p class="noticeDetail-cnt">
          {{ `조회수 : ${NotcDetail.readCnt}` }}
        </p>
        <p class="noticeDetail-regDt">
          {{ `등록일자 : ${formatDate(NotcDetail.regDt)}` }}
        </p>
      </div>
      <div class="bordCont-Content">
        <div v-html="NotcDetail.bordCont"></div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  components: {},
  props: {
    NotcDetail: {
      type: Object,
    },
    ix: {
      type: Number,
      default: 0,
    },
    bordNumber: null,
  },
  data() {
    return {
      bordNum: this.bordNumber,
    }
  },
  watch: {},
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    toggleAnswer(bordNo) {
      if (bordNo === 'close') {
        this.bordNumber = null
      } else {
        this.bordNumber = bordNo
      }
    },
    //공유하기
  },
  async created() {
    this.$nextTick(() => {
      window.$('img[usemap]').rwdImageMaps()
    })
  },
  updated() {},
}
</script>

<style></style>
