<template>
  <section
    v-if="noticePopupList.length > 0 && noticePopup"
    class="notice-popup"
  >
    <div
      class="notice-popup swiper-container notice_popup_list"
      id="noticePopup"
    >
      <div class="header">
        <!-- <h2 id="noticePopupTitle">NOTICE</h2> -->
        <h2 v-if="!isShowTitle" id="noticePopupTitle">
          {{ noticePopupList[0].propCd === '1' ? 'NOTICE' : 'NEWS&EVENT' }}
        </h2>
        <h2 v-if="isShowTitle" id="noticePopupTitle">
          {{ isTitle === true ? 'NOTICE' : 'NEWS&EVENT' }}
        </h2>
        <img
          @click="doClose"
          class="close-icon"
          src="~assets/image/ico/close.svg"
          alt="닫기"
        />
      </div>
      <notice-popup-body
        v-for="(NotcDetail, idx) in NotcPops"
        :key="`NotcDetail_${idx}`"
        :NotcDetail="NotcDetail"
        :ix="idx"
        :bordNumber="bordNumber"
        :toggleAnswer="toggleAnswer"
      ></notice-popup-body>
      <div :class="[isList ? 'on' : 'to']" class="more-con">
        <div class="header">
          <h2 id="noticePopupTitle">NOTICE LIST</h2>
          <img
            @click="listOn('NON')"
            class="close-icon"
            src="~assets/image/ico/close.svg"
            alt="닫기"
          />
        </div>
        <div
          :class="{ on: bordNumber === NotcList.bordNo }"
          class="more-list"
          @click="toggleList(NotcList.bordNo)"
          v-for="(NotcList, idx) in noticePopupList"
          :key="`NotcList_${idx}`"
        >
          <span v-if="NotcList.propCd === '1'">&#91;공지사항&#93;</span>
          <span v-if="NotcList.propCd === '2'"
            >&#91;이벤트 &#38; 뉴스&#93;</span
          >
          <span>{{ NotcList.bordNm }}</span>
        </div>
      </div>
      <div class="more-option">
        <button
          :style="{ display: isList ? 'inline-block' : 'none' }"
          class="minus"
          @click="listOn('NON')"
        >
          <i class="el-icon-remove"></i>
        </button>
        <button
          :style="{ display: !isList ? 'inline-block' : 'none' }"
          class="plus"
          @click="listOn('ON')"
        >
          <i class="el-icon-circle-plus"></i>
        </button>
      </div>
      <div class="swiper-option">
        <div class="swiper-pagination swiper-pagination-fraction"></div>
        <button
          :style="{ display: isStop ? 'inline-block' : 'none' }"
          class="start"
          @click="startClick"
        >
          <i class="el-icon-video-play"></i>
        </button>
        <button
          :style="{ display: !isStop ? 'inline-block' : 'none' }"
          class="stop"
          @click="stopClick"
        >
          <i class="el-icon-video-pause"></i>
        </button>
      </div>
      <div class="Line">Line</div>
      <div class="swiper-wrapper Notc-con">
        <notice-popup-item
          id="noticeEl"
          :class="{ on: isList === true }"
          v-for="(NotcItem, idx) in noticePopupList"
          :key="`NotcItem_${idx}`"
          :NotcPop="NotcItem"
          :ix="idx"
          @toggleAnswer="toggleAnswer(NotcItem.bordNo, idx)"
        >
        </notice-popup-item>
      </div>
      <!-- <a class="pagination" href="#$%^"></a> -->
      <div class="bottom">
        <span @click="doAweekClose">일주일간 보지않기</span>
        <span @click="doClose">닫기</span>
      </div>
    </div>
  </section>
</template>

<script>
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import NoticePopupItem from 'Components/community/partials/NoticePopupItem'
import NoticePopupBody from 'Components/community/partials/NoticePopupBody'
import { getBoardsList, getUpdateReadCnt } from 'Api/modules'
import _ from 'lodash'
import Swiper from 'swiper'
import 'swiper/css/swiper.css'

export default {
  components: { NoticePopupItem, NoticePopupBody },
  data() {
    return {
      // el: window.$('#more-con'),
      NotcPops: [],
      propCd: '1,2',
      bordNumber: null,
      // bordNo: '',
      noticeTitle: '',
      noticePopup: true,
      now: new Date(),
      btDay: '',
      todayDate: '',
      isStop: false,
      isList: false,
      isTitle: false,
      isShowTitle: false,
    }
  },
  computed: {
    noticePopupList: function () {
      var year = this.now.getFullYear()
      var month = this.now.getMonth()
      var day = this.now.getDate()
      var todayDate = new Date(year, month, day)
      // var endDate = new Date('2022-08-15')
      // var btMs = endDate.getTime() - todayDate.getTime()
      // var btDay = btMs / (1000 * 60 * 60 * 24)
      return _.filter(
        this.NotcPops,
        NotcPopsList =>
          NotcPopsList.popYn === 'Y' &&
          new Date(NotcPopsList.popEndDate) > todayDate,
      )
    },
    noticeDetailList: function () {
      var year = this.now.getFullYear()
      var month = this.now.getMonth()
      var day = this.now.getDate()
      var todayDate = new Date(year, month, day)
      // var endDate = new Date('2022-08-15')
      // var btMs = endDate.getTime() - todayDate.getTime()
      // var btDay = btMs / (1000 * 60 * 60 * 24)
      return _.filter(
        this.NotcPops,
        NotcDetailPopsList =>
          NotcDetailPopsList.popYn === 'Y' &&
          new Date(NotcDetailPopsList.popEndDate) > todayDate,
      )
    },
  },
  watch: {
    NotcPops(NotcPops) {
      if (NotcPops) {
        this.initSwiper()
      }
    },
  },
  methods: {
    async search() {
      const res = await getBoardsList({
        schKeyword: this.searchText,
        pageNumber: this.pageNumber,
        propCd: this.propCd,
        bordNo: this.bordNo,
        pageSize: this.pageSize,
      })
      this.NotcPops = res.content
      this.totalElements = res.totalElements
      this.currentPageIndex = res.pageable.pageNumber + 1
      this.pageNumber = res.pageable.pageNumber
      this.pageSize = res.pageable.pageSize
      this.no = this.totalElements - (this.currentPageIndex - 1) * this.pageSize
      // this.bordNumber = this.noticeDetailList[0].bordNo
      this.bordNumber =
        this.noticeDetailList.length === 0
          ? (this.bordNumber = null)
          : this.noticeDetailList[0].bordNo
    },
    listOn(status) {
      if (status === 'ON') {
        this.isList = true
        this.stopClick()
      } else if (status === 'NON') {
        this.isList = false
        this.startClick()
      }
    },
    slideClick() {
      this.bannerSlides.autoplay.start()
    },
    startClick() {
      this.isStop = false
      this.bannerSlides.autoplay.start()
    },
    stopClick() {
      this.isStop = true
      this.bannerSlides.autoplay.stop()
    },
    toggleAnswer(bordNo, idx) {
      if (bordNo === 'close') {
        this.bordNumber = null
      } else {
        this.bordNumber = bordNo
        this.isShowTitle = true
        this.updateReadCnt()
        if (this.noticePopupList[idx].propCd === '1') {
          this.isTitle = true
        } else {
          this.isTitle = false
        }
      }
      console.log(this.noticePopupList[idx].propCd)
      this.$emit('toggleAnswer', this.bordNumber)
    },
    toggleList(bordNo) {
      this.bordNumber = bordNo
      this.isList = false
      this.updateReadCnt()
    },
    doAweekClose() {
      if (this.$cookies.set('bannerModal', 'Y', '7d')) {
        this.noticePopup = false
      }
    },
    doClose() {
      this.noticePopup = false
    },
    initSwiper() {
      this.$nextTick(() => {
        if (this.noticePopup === true) {
          this.bannerSlides = new Swiper('#noticePopup', {
            slidesPerView: 1,
            spaceBetween: 30,
            autoHeight: true,
            // loop: true,
            // loopedSlides: 3,
            direction: 'horizontal',
            disableOnInteraction: false,
            autoplay: {
              delay: 3000,
              disableOnInteraction: true,
            },
            on: {
              activeIndexChange: function () {
                // this.bannerSlides.slideTo(2, 1000, false)
                // console.log(this.realIndex)
              },
            },

            pagination: {
              el: '.swiper-option .swiper-pagination',
              clickable: true,
              type: 'fraction',
              renderBullet: function (index, className) {
                return (
                  '<span class="' + className + '">' + (index + 1) + '</span>'
                )
              },
            },
          })
          // if (this.noticePopupList.length === 1) {
          //   this.bannerSlides.autoplay.stop()
          // }
        }
      })
    },
    cookiesScan() {
      if (this.$cookies.isKey('bannerModal')) {
        this.noticePopup = false
      } else {
        this.noticePopup = true
      }
    },
    async updateReadCnt() {
      try {
        await getUpdateReadCnt({
          bordNo: this.bordNumber,
        })
      } catch (e) {
        console.error(e)
      }
    },
  },
  async created() {
    // console.log(this.noticePopupList)

    await this.cookiesScan()
    await this.search()
    // this.noticePopupList[0].propCd === 1
    //   ? (this.isTitle = true)
    //   : this.noticePopupList[0].propCd === 2
    //   ? (this.isTitle = false)
    //   : undefined
  },
}
</script>
<style>
.btn-save01 {
  width: 300px;
  height: 50px;
  display: flex;
  border-radius: 0.5rem;
  background: #ef7c4a;
  color: #fff;
  font-weight: bold;
  align-items: center;
  margin: 10px auto;
  justify-content: center;
}
.notice-popup
  #noticePopup
  .notice-Popup-Body
  .noticeDetail
  .bordCont-Content
  div
  .btn-save01
  img {
  width: 20px !important;
  margin: 0 0 0 5px !important;
}
</style>
